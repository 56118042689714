import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { KnobType, TransformKnob } from 'components';
import { Accordion } from 'components/Accordion';
import { Flex } from 'components/layout';
import { fabric } from 'fabric';
import { useAppSelector, useCanvas, useFabricCore } from 'hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveStickerTransformValues, update } from 'redux/reducers/active-sticker';
import { selectStickersWidget } from 'redux/reducers/widget';
import { GACategory } from 'utils';

const Sticker = styled.button(
  () => css`
    background: transparent;
    border: none;
    max-width: 58px;
    cursor: pointer;
    padding: 0;
    margin: 10px;

    & img {
      max-height: 100%;
      max-width: 100%;
      display: block;
    }
  `
);

const StickersEditorContainer = styled.div`
  display: flex;
`;

export const StickerEditor = () => {
  const { canvas, activeSticker, setActiveSticker, userImage, stickerActions } = useCanvas();
  const { data: stickersData } = useSelector(selectStickersWidget);
  const { addImage } = useFabricCore();
  const activeStickerValues = useSelector(selectActiveStickerTransformValues);
  const dispatch = useDispatch();
  const copy = useAppSelector((state) => state.widget.mainWidget.text.stylizer.edit_stickers);
  const canEdit = userImage !== null;

  useEffect(() => {
    if (activeSticker) {
      dispatch(
        update({
          scale: activeSticker.scaleX || 0,
          rotate: activeSticker.angle || 0,
          moveX: activeSticker.left || 0,
          moveY: activeSticker.top || 0,
        })
      );
    }
  }, [activeSticker, dispatch]);

  const addSticker = async (index: number) => {
    const newSticker = await addImage(stickersData[index].image);
    newSticker.center();
    newSticker.setControlsVisibility({ mt: false, mb: false, mr: false, ml: false });
    newSticker.on('selected', (e) => setActiveSticker(e.target as fabric.Image));
    newSticker.on('moved', (e) => dispatch(update({ moveX: e.target?.left || 0, moveY: e.target?.top || 0 })));
    newSticker.on('scaled', (e) => dispatch(update({ scale: e.target?.scaleX || 1 })));
    newSticker.on('rotated', (e) => dispatch(update({ rotate: e.target?.angle || 1 })));
    canvas?.setActiveObject(newSticker);
    setActiveSticker(newSticker);
    stickerActions.push(newSticker);
  };

  const renderStickers = () => {
    return stickersData.map((sticker, index) => {
      return (
        <Sticker
          data-ga-category={GACategory.STICKER}
          data-ga-label={sticker.aria_label}
          key={index}
          onClick={() => addSticker(index)}>
          <img crossOrigin='anonymous' src={sticker.image} alt={`${sticker.aria_label}`} />
        </Sticker>
      );
    });
  };

  const handleChange = (event: any, type: KnobType, value: any) => {
    if (activeSticker) {
      switch (type) {
        case 'moveX':
          activeSticker.set('left', value);
          break;
        case 'moveY':
          activeSticker.set('top', value);
          break;
        case 'scale':
          activeSticker.scale(value);
          break;
        case 'rotate':
          activeSticker.rotate(value);
          break;
      }

      activeSticker.setCoords();
      dispatch(update({ [type]: value }));
    }
  };
  return (
    <Accordion title={copy.headline} disabled={!canEdit}>
      <div>
        <p dangerouslySetInnerHTML={{ __html: copy.description }} />
        <div>{renderStickers()}</div>

        <div>
          {activeSticker && (
            <StickersEditorContainer>
              <Flex>
                <p dangerouslySetInnerHTML={{ __html: copy.move_text }} />
                <TransformKnob type={'moveX'} onChange={handleChange} value={activeStickerValues.moveX}></TransformKnob>
                <TransformKnob type={'moveY'} onChange={handleChange} value={activeStickerValues.moveY}></TransformKnob>
                <p dangerouslySetInnerHTML={{ __html: copy.scale_text }} />
                <TransformKnob type={'scale'} onChange={handleChange} value={activeStickerValues.scale}></TransformKnob>
                <p dangerouslySetInnerHTML={{ __html: copy.rotate_text }} />
                <TransformKnob
                  type={'rotate'}
                  onChange={handleChange}
                  value={activeStickerValues.rotate}></TransformKnob>
              </Flex>
            </StickersEditorContainer>
          )}
        </div>
      </div>
    </Accordion>
  );
};
