/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { LoadingSpinner } from '@telescope/react-components';
import { isTrue } from '@telescope/utility-helpers';
import { Button, Flex } from 'components';
import { useAppSelector, useCanvas, useTailor } from 'hooks';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { selectFormData } from 'redux/reducers/user-form';
import { FileUtil, GACategory, generateTailorConfig, mq, TailorJobStatus } from 'utils';

const ShareContainer = styled.div(({ theme }) => {
  const { container } = theme.thank_you;
  return {
    ...container,

    '.btn-create-another': {
      fontSize: 16,
    },
  };
});

const ShareButton = styled.button((props) => {
  return {
    width: 32,
    height: 32,
    border: 0,
    background: 'transparent',
    cursor: 'pointer',
    padding: 0,

    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },

    [mq.desktop]: {
      width: 50,
      height: 50,
    },
  };
});

const Headline = styled.h2(({ theme }) => {
  const { desktop, ...defaultStyles } = theme.thank_you.headline;
  return {
    ...defaultStyles,

    [mq.desktop]: {
      ...desktop,
    },
  };
});

const Description = styled.p(({ theme }) => {
  const { desktop, ...defaultStyles } = theme.thank_you.description;
  return {
    marginBottom: 20,
    ...defaultStyles,

    [mq.desktop]: {
      ...desktop,
    },
  };
});

const ShareHeadline = styled.h3(({ theme }) => {
  const { desktop, ...defaultStyles } = theme.thank_you.share;
  return {
    ...defaultStyles,

    [mq.desktop]: {
      ...desktop,
    },
  };
});

const ShareLink = ShareButton.withComponent('a');

interface ShareProps {
  imageUrl?: string;
  onCreateAnother?: () => void;
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const Share: FC<ShareProps> = ({ onCreateAnother, imageUrl }) => {
  const { frame } = useCanvas();
  const { ruleset_collection_id, tailor_topic_id } = useAppSelector(
    (state) => state.widget.mainWidget.text.app_settings
  );
  const settings = useAppSelector((state) => state.widget.mainWidget.settings);
  const copy = useAppSelector((state) => state.widget.mainWidget.text.thank_you);
  const { wid, sid } = queryString.parse(window.location.search);
  const handleImageReset = () => {
    onCreateAnother && onCreateAnother();
  };
  const tailor = useTailor();
  const [outputImageUrl, setOutputImageUrl] = useState<string | null>(null);
  const formValues = useAppSelector(selectFormData);

  /**
   * Callback fired during the upload process
   */
  const onUploadProgress = (params: { loaded: number; total: number }) => {
    const { loaded, total } = params;
    const percent = Math.floor((loaded * 100) / total);
  };

  /**
   * Checks if the given job is finished processing. If so, returns the output url,
   * otherwise it keeps polling until it finishes or an error is thrown
   * @param jobId jobID to get the image URL of
   */
  const getOutputImageUrl = useCallback(
    async (jobId: string): Promise<string> => {
      const { status, actions } = (await tailor.getJob(jobId)).data;

      if (status === TailorJobStatus.ERROR) {
        throw new Error('File could not be uploaded');
      }
      if (status === TailorJobStatus.FINISHED) {
        const filePath = actions[2].output_media[0].url.split('/').pop();
        return settings.tailor_output_url + filePath;
      }

      // Try again after a delay
      await delay(1000);
      return await getOutputImageUrl(jobId);
    },
    [tailor, settings.tailor_output_url]
  );

  /**
   * If an imageUrl is present, upload to S3 and await the output URL
   */
  useEffect(() => {
    (async () => {
      if (imageUrl) {
        // Trim all form values
        const values = Object.entries(formValues).reduce((acc: Record<string,any>, [key, value])=> {
          acc[key] = (typeof value === 'string'? value.trim() : value)
          return acc;
        }, {})
        const tailorConfig = generateTailorConfig({
          flex: {
            topics: tailor_topic_id,
            ruleset_collection_id: ruleset_collection_id || '0',
            custom_parameters: JSON.stringify({ upic_filter: frame?.frame_name, ...values }),
            screen_name: values.screen_name,
            user_name: values.user_name
          },
        });
        const { job_id } = (await tailor.createJob(tailorConfig)).data;
        const blob = FileUtil.dataURItoBlob(imageUrl);
        const file = new File([blob], 'upic.png', { type: 'image/png' });
        await tailor.uploadImage(job_id, file, '1', onUploadProgress);
        await tailor.startJob(job_id);
        const outputImageUrl = await getOutputImageUrl(job_id);

        setOutputImageUrl(outputImageUrl);
      }
    })();
  }, [imageUrl, tailor, getOutputImageUrl, tailor_topic_id, ruleset_collection_id, formValues, frame?.frame_name]);

  const handleTwitterShare = (e: React.MouseEvent<HTMLButtonElement>, image: string) => {
    e.preventDefault();
    const description = encodeURIComponent(copy.share.twitter.share_description);
    const shareUrl = encodeURIComponent(
      queryString.stringifyUrl({
        url: `${window.location.origin}/share.php`,
        query: {
          image: isTrue(copy.share.twitter.use_image_url) ? image : null,
          wid,
          sid,
        },
      })
    );
    const url = `https://twitter.com/intent/tweet?text=${description}&url=${shareUrl}`;
    window.open(url, '_blank', 'height=420,width=550');
  };

  const handleFacebookShare = (e: React.MouseEvent<HTMLButtonElement>, image: string) => {
    e.preventDefault();
    const description = copy.share.facebook.share_description;
    const shareUrl = encodeURIComponent(
      queryString.stringifyUrl({
        url: `${window.location.origin}/share.php`,
        query: {
          image: isTrue(copy.share.facebook.use_image_url) ? image : null,
          wid,
          sid,
        },
      })
    );
   
    const quote = description ? `&quote=${encodeURIComponent(description)}` : '';

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}${quote}`,
      'popup',
      'width=600, height=400, scrollbars=no'
    );
  };

  return (
    <ShareContainer>
      <Headline dangerouslySetInnerHTML={{ __html: copy.headline }}></Headline>
      <Description dangerouslySetInnerHTML={{ __html: copy.description }}></Description>
      <Flex inline gap={10} style={{ marginBottom: 30 }}>
        {copy.gallery_link.url && (
          <a
            href={copy.gallery_link.url}
            target={isTrue(copy.gallery_link.open_new_window) ? '_blank' : 'self'}
            rel='noreferrer'>
            {copy.gallery_link.text}
          </a>
        )}
        <Button className='btn-create-another' onClick={handleImageReset}>
          {copy.button_create_another}
        </Button>
      </Flex>

      <Flex gap={10}>
        <ShareHeadline dangerouslySetInnerHTML={{ __html: copy.share.headline }}></ShareHeadline>

        {outputImageUrl ? (
          <Flex horizontal gap={10}>
            {isTrue(copy.share.twitter.display) && (
              <ShareButton
                data-ga-category={GACategory.SHARE}
                data-ga-label={copy.share.twitter.aria_label}
                onClick={(e) => handleTwitterShare(e, outputImageUrl)}
                aria-label={copy.share.twitter.aria_label}>
                <img role='presentation' src={copy.share.twitter.icon} alt='' />
              </ShareButton>
            )}
            {isTrue(copy.share.facebook.display) && (
              <ShareButton
                data-ga-category={GACategory.SHARE}
                data-ga-label={copy.share.facebook.aria_label}
                onClick={(e) => handleFacebookShare(e, outputImageUrl)}
                aria-label={copy.share.facebook.aria_label}>
                <img role='presentation' src={copy.share.facebook.icon} alt='' />
              </ShareButton>
            )}
            {isTrue(copy.share.email.display) && (
              <ShareLink
                data-ga-category={GACategory.SHARE}
                data-ga-label={copy.share.email.aria_label}
                href={`mailto:?subject=${encodeURIComponent(copy.share.email.subject)}&body=${encodeURIComponent(
                  copy.share.email.message + ' ' + outputImageUrl
                )}`}
                target='_top'
                aria-label={copy.share.email.aria_label}>
                <img role='presentation' src={copy.share.email.icon} alt='' />
              </ShareLink>
            )}
            {isTrue(copy.share.download.display) && (
              <ShareLink
                data-ga-category={GACategory.SHARE}
                data-ga-label={copy.share.download.aria_label}
                href={outputImageUrl}
                target='_blank'
                download='My Image'
                aria-label={copy.share.download.aria_label}>
                <img role='presentation' src={copy.share.download.icon} alt='' />
              </ShareLink>
            )}
          </Flex>
        ) : (
          <LoadingSpinner size={40} />
        )}
      </Flex>
    </ShareContainer>
  );
};