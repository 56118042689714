/** @jsxImportSource @emotion/react */
import { css, Global as EmotionGlobal, useTheme } from '@emotion/react';

export const Global = () => {
  const theme = useTheme();
  const globalStyles = css({
    body: {
      fontFamily: theme.global.fonts.secondary,
    },
    'h1,h2,h3,h4,h5,h6': {
      ...theme.global.headlines,
    },
    p: {
      ...theme.global.paragraphs,
    },
  });
  return <EmotionGlobal styles={globalStyles}></EmotionGlobal>;
};
