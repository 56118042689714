import styled from '@emotion/styled';
import { Accordion, Button, Flex } from 'components';
import { CameraIcon, UploadIcon } from 'components/icons';
import { useAppSelector, useCanvas, useFabricCore, useMediaQuery } from 'hooks';
import React, { FC, useEffect, useRef, useState } from 'react';
import { GAAction, GACategory, mq, trackGoogleEvent, WebRTCUtil } from 'utils';

const unicodeCircledSmallLetterX = '"\\24E7"';
const unicodeCheckMark = '"\\2713"';

const ImageCaptureContainer = styled.div(({ theme }) => {
  return {
    [mq.desktop]: {
      p: {
        marginBottom: 15,
      },
    },
  };
});

const StatusMessage = styled.span<{ statusType: 'success' | 'error' }>(({ theme, statusType }) => {
  const { error, success } = theme.global.colors;
  return {
    fontSize: 14,
    color: statusType === 'error' ? error : success,
    '& i::before': {
      content: statusType === 'error' ? unicodeCircledSmallLetterX : unicodeCheckMark,
      paddingRight: '10px',
      speak: 'none',
      fontStyle: 'normal',
    },
  };
});

/**
 * When a new photo is uploaded, move focus to the next section
 * @param currentElement target element that triggered the event 
 */
const focusNextSection = (currentElement: EventTarget) => {
  const accordionQuerySelector = '[id^=accordion__]';
  const currentSection = (currentElement as HTMLElement).closest(accordionQuerySelector);
  const allSections = Array.from(document.querySelectorAll<HTMLDivElement>(accordionQuerySelector));
  const curIndex = allSections.indexOf(currentSection as HTMLDivElement);

  if (curIndex < allSections.length) {
    allSections[curIndex + 1].focus();
  }
};

interface ImageCaptureProps {
  allowCamera?: boolean;
  onImageCaptured?(image: string | null): any;
  onImageUploaded?(image: string): any;
  onPreviewStream?(stream: HTMLVideoElement | null): any;
}
export const ImageCapture: FC<ImageCaptureProps> = ({
  onImageCaptured,
  allowCamera = true,
  onPreviewStream,
  onImageUploaded,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hasCamera, setHasCamera] = useState<boolean>(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { canvas, userImage, setUserImage } = useCanvas();
  const { addImage, deleteObject, addVideo } = useFabricCore();
  const [previewObject, setPreviewObject] = useState<fabric.Image | null>(null);
  const isMobile = useMediaQuery(['(min-width: 980px)'], [false], true);
  const copy = useAppSelector((state) => state.widget.mainWidget.text.stylizer.select_photo);
  const [alertMessage, setAlertMessage] = useState<{ type: 'success' | 'error'; message: string } | null>(null);

  /**
   * Updates the captured user image and renders it to the canvas.
   * Since only one user image can be added, removes the previous one, if present
   */
  const updateUserImage = async (imageUrl: string) => {
    if (canvas) {
      const newImage = await addImage(imageUrl, {
        left: 0,
        top: 0,
        centeredRotation: true,
        centeredScaling: true,
      });
      newImage.scaleToWidth(canvas.width ?? 0);
      newImage.sendToBack();

      // Remove the previous image and update ref to store current image
      if (userImage) {
        deleteObject(userImage);
      }
      setUserImage(newImage);
      setAlertMessage({ type: 'success', message: copy.success_message });
    }
  };

  /**
   * If cameraPreview is set, renders the videoElement onto
   * the canvas. If cameraPreview is unset, removes it from the Canvas
   */
  useEffect(() => {
    (async () => {
      if (canvas && isPreviewing && videoRef.current) {

        const webcam = addVideo(videoRef.current, {
          top: 0,
          left: 0,
          selectable: false,
          evented: false,
        }) as fabric.Image;

        webcam.sendToBack();
        const el = webcam.getElement() as HTMLMediaElement;
        await el.play();
        setPreviewObject(webcam);
      }
    })();
  }, [addVideo, canvas, isPreviewing]);

  /**
   * Detects if the user has a camera available
   */
  useEffect(() => {
    (async () => {
      const hasCamera = await WebRTCUtil.hasVideoCamera();
      setHasCamera(hasCamera);
    })();
  }, []);

  /**
   * Triggers file input click to open file browser
   */
  const handleUploadClick = () => {
    inputRef.current?.click();
    setAlertMessage(null);
  };

  /**
   * When user selects a file, creates a URL and updates the image
   */
  const handleFileChange = (e: React.FormEvent<HTMLInputElement>) => {
    const file = inputRef.current && inputRef.current.files && inputRef.current.files[0];

    if (!file) return;

    const fileUrl = URL.createObjectURL(file);

    if (isPreviewing) {
      stopPreviewing();
    }

    updateUserImage(fileUrl);
    focusNextSection(e.target);
  };

  const handleCaptureClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    trackGoogleEvent(GAAction.BUTTON_CLICK, 
      { content: isPreviewing ? copy.button_take_photo : copy.button_use_camera, 
        category: GACategory.SELECT_PHOTO
      });
    // If the user is in a previewing state already, trigger the snapshot
    if (isPreviewing && videoRef.current) {
      const imageUrl = WebRTCUtil.captureScreenshot(videoRef.current);

      updateUserImage(imageUrl);
      stopPreviewing();
      focusNextSection(e.target);
      return;
    }

    // If not previewing yet, set up the stream and clear any previous image
    if (videoRef.current) {
      try {
        const constraints = {
          video: {
            facingMode: 'user',
            width: { ideal: canvas?.width },
            height: { ideal: canvas?.height },
          },
        };
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        videoRef.current.srcObject = stream;

        if (userImage) {
          deleteObject(userImage);
          setUserImage(null);
        }
        setIsPreviewing(true);
        setAlertMessage(null);
      } catch (error) {

        if( !(error instanceof Error)) {
          throw error;
        } else {
          console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
          setAlertMessage({ type: 'error', message: copy.error_camera_permission });
        }
      }
    }
  };

  /**
   * Delete the camera preview from the canvas and stop the camera stream
   */
  const stopPreviewing = () => {
    setIsPreviewing(false);

    previewObject && deleteObject(previewObject);

    if (!videoRef.current) return;

    const stream = videoRef.current.srcObject as MediaStream;
    stream.getTracks().map((track) => track.stop());
  };

  const renderContent = () => {
    return (
      <ImageCaptureContainer>
        <video
          style={{ display: 'none' }}
          height={canvas?.height}
          width={canvas?.width}
          muted
          autoPlay
          playsInline
          controls={false}
          ref={videoRef}
          aria-hidden='true'></video>
        <input
          style={{ display: 'none' }}
          aria-hidden='true'
          type='file'
          accept='image/*'
          ref={inputRef}
          onChange={handleFileChange}
        />

        {!isMobile && <p dangerouslySetInnerHTML={{ __html: copy.description }} />}
        <Flex horizontal={isMobile} inline gap={10}>
          {hasCamera && (
            <Button
              data-ga-no-track
              className='camera-button'
              onClick={(e) => handleCaptureClick(e)}
              icon={<CameraIcon aria-hidden='true' focusable='false' />}>
              {isPreviewing ? copy.button_take_photo : copy.button_use_camera}
            </Button>
          )}
          <Button
            data-ga-category={GACategory.SELECT_PHOTO}
            onClick={() => handleUploadClick()}
            icon={<UploadIcon aria-hidden='true' focusable='false' />}>
            {copy.button_upload_photo}
          </Button>
        </Flex>
        <Flex padding={'10px 0 0 0'}>
          {alertMessage && (
            <StatusMessage role='status' aria-live='polite' statusType={alertMessage.type}>
              <i aria-hidden='true'></i>
              {alertMessage.message}
            </StatusMessage>
          )}
        </Flex>
      </ImageCaptureContainer>
    );
  };

  if (isMobile) {
    return renderContent();
  }

  return <Accordion title={copy.headline}>{renderContent()}</Accordion>;
};
