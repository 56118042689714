import styled from '@emotion/styled';
import { FC } from 'react';
import { mq } from 'utils';

type ButtonVariant = 'solid' | 'outline';

const StyledButton = styled.button<{ isDisabled?: boolean }>(({ theme }) => {
  const { desktop, disabled, hover, active, ...buttonDefaults } = theme.global.buttons;

  return {
    padding: '10px 15px',
    borderRadius: 3,
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: 12,
    display: 'inline-flex',
    alignItems: 'center',
    ...buttonDefaults,

    i: {
      marginRight: 5,
    },

    ':hover:enabled': {
      ...hover,
    },

    ':active:enabled': {
      ...active,
    },

    ':disabled, [aria-disabled="true"]': {
      cursor: 'not-allowed',
      ...disabled,
    },

    [mq.desktop]: {
      ...desktop,

      i: {
        marginRight: 8,
      },
    },
  };
});

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  variant?: ButtonVariant;
  icon?: React.ReactNode;
}
export const Button: FC<ButtonProps> = ({ isDisabled, icon, children, ...props }) => {
  return (
    <StyledButton disabled={isDisabled} {...props}>
      {icon && <i>{icon}</i>}
      {children}
    </StyledButton>
  );
};
