import styled from '@emotion/styled';

export const ErrorLabel = styled.span((props) => {
  return {
    display: 'block',
    paddingTop: 6,
    fontSize: 12,
    color: props.theme.global.colors.error,
  };
});

export const HelpTextLabel = styled.span((props) => {
  return {
    display: 'block',
    fontSize: 12,
    color: '#605E5C',
    fontStyle: 'italic',
  };
});
