import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface UserImageProperties {
  moveX: number;
  moveY: number;
  scale: number;
  rotate: number;
  flip: boolean;
  color: number;
  brightness: number;
  contrast: number;
}
interface UserImageState {
  status: string;
  properties: UserImageProperties;
}

const initialState: UserImageState = {
  status: '',
  properties: {
    moveX: 0,
    moveY: 0,
    scale: 1,
    rotate: 0,
    flip: false,
    color: 0,
    brightness: 0,
    contrast: 0,
  },
};

export const userImageSlice = createSlice({
  name: 'userImage',
  initialState: initialState,
  reducers: {
    update: (state: UserImageState, action: PayloadAction<Partial<UserImageProperties>>) => {
      const payload: Partial<UserImageProperties> = action.payload;
      state.properties = { ...state.properties, ...payload };
    },
    reset: (state: UserImageState) => {
      state.properties = { ...initialState.properties };
    },
  },
});

export const { update, reset } = userImageSlice.actions;

// Selectors
export const selectUserImageTransformValues = (state: RootState) => state.userImageTransformValues.properties;

export default userImageSlice.reducer;
