import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Slider, SliderProps, ThemeProvider, useTheme as useMuiTheme } from '@material-ui/core';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/icons';
import { Flex } from 'components/layout';
import { FC } from 'react';

export type KnobType = 'moveX' | 'moveY' | 'scale' | 'rotate' | 'flip' | 'color' | 'brightness' | 'contrast';
export type Point = { x: number; y: number };

const SliderWrapper = styled.div(({ theme }) => {
  const { rail, track, thumb, root } = theme.stylizer.sliders;
  return {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > *': {
      flex: '0 1 calc(100% - 30px)',
    },
    '& .MuiSlider-markLabel': {
      top: '26px !important',
    },
    '& .MuiSlider-root': {
      ...root,
    },
    '& .MuiSlider-rail': {
      ...rail,
    },
    '& .MuiSlider-track': {
      ...track,
    },
    '& .MuiSlider-thumb': {
      width: 14,
      height: 14,
      ...thumb,
    },
  };
});

const SliderLabel = styled.span((props) => {
  return {
    paddingTop: 8,
  };
});

const FlipButton = styled.button<{ active: boolean }>(({ active, theme }) => {
  const styles = theme.stylizer.edit_photo.buttons;
  const backgroundColor = active ? styles.active.background : styles.background;
  const textColor = active ? styles.active.color : styles.color;
  return {
    cursor: 'pointer',
    color: textColor,
    fontWeight: 'bold',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: backgroundColor,
    border: '1px solid #E1E1E1',
    boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.10)',
    borderRadius: 4,
    width: 34,
    height: 34,

    i: {
      marginBottom: 12,
    },

    svg: {
      width: 18,
      height: 18,
    },

    'svg path': {
      fill: textColor,
    },

    ':disabled': {
      cursor: 'default',
      opacity: 0.75,
    },

    ':hover:enabled, [aria-pressed="true"]': {
      background: styles.active.background,
      color: styles.active.color,
    },

    ':hover:enabled svg path, [aria-pressed="true"] svg path': {
      fill: styles.active.color,
    },
  };
});

interface TransformKnobProps extends Omit<SliderProps, 'value' | 'onChange'> {
  type: KnobType;
  onChange: (event: any, type: KnobType, value: any) => void;
  value: number | boolean;
}

const DEFAULT_KNOB_PROPS = {
  moveX: {
    'aria-label': 'Move X',
    getAriaValueText: (value: number) => `${value} pixels`,
    defaultValue: 0,
    step: 1,
    min: 0,
    max: 500,
    marks: [
      { label: '0', value: 0 },
      { label: '500', value: 500 },
    ],
  },
  moveY: {
    'aria-label': 'Move Y',
    getAriaValueText: (value: number) => `${value} pixels`,
    defaultValue: 0,
    step: 1,
    min: 0,
    max: 500,
  },
  scale: {
    'aria-label': 'Scale',
    defaultValue: 1.0,
    step: 0.1,
    min: 0.1,
    max: 3.0,
    marks: [
      { label: '0.1', value: 0.1 },
      { label: '1.0', value: 1.0 },
      { label: '3.0', value: 3.0 },
    ],
  },
  rotate: {
    'aria-label': 'Rotate',
    getAriaValueText: (value: number) => `${value}°`,
    valueLabelFormat: (value: number) => `${value}°`,
    min: -180,
    max: 180,
    defaultValue: 0,
    marks: [
      { label: '-180°', value: -180 },
      { label: '0°', value: 0 },
      { label: '180°', value: 180 },
    ],
  },
  flip: {},
  color: {
    'aria-label': 'Color',
    defaultValue: 0,
    min: -100,
    max: 100,
    marks: [
      { label: '-100', value: -100 },
      { label: '0', value: 0 },
      { label: '100', value: 100 },
    ],
  },
  brightness: {
    'aria-label': 'Brightness',
    defaultValue: 0,
    min: -255,
    max: 255,
    marks: [
      { label: '-255', value: -255 },
      { label: '0', value: 0 },
      { label: '255', value: 255 },
    ],
  },
  contrast: {
    'aria-label': 'Contrast',
    defaultValue: 0,
    min: -255,
    max: 255,
    marks: [
      { label: '-255', value: -255 },
      { label: '0', value: 0 },
      { label: '255', value: 255 },
    ],
  },
};

export const TransformKnob: FC<TransformKnobProps> = ({ type, onChange, value, ...props }) => {
  const theme = useTheme();
  const materialUItheme = useMuiTheme();
  const renderKnob = (type: KnobType) => {
    const config = DEFAULT_KNOB_PROPS[type];

    const handleChange = (e: any, value: number | number[] | boolean) => {
      onChange(e, type, value);
    };

    if (type === 'moveX' || type === 'moveY') {
      return (
        <Flex horizontal verticalAlign='start' gap={10}>
          <SliderLabel>{type === 'moveX' ? 'X' : 'Y'}:</SliderLabel>
          <SliderWrapper theme={theme}>
            <Slider
              key={`slider-${type}`}
              {...config}
              value={value as number}
              onChange={handleChange}
              valueLabelDisplay='auto'
              {...props}></Slider>
          </SliderWrapper>
        </Flex>
      );
    }

    if (type === 'flip') {
      return (
        <Flex horizontal verticalAlign='center' gap={10}>
          <span>Choose:</span>
          <Flex horizontal gap={5}>
            <FlipButton
              theme={theme}
              aria-label='Flip Left'
              aria-pressed={value === false}
              active={value === false}
              onClick={(e) => handleChange(e, !value)}>
              <ArrowRightIcon />
            </FlipButton>
            <FlipButton
              theme={theme}
              aria-label='Flip Right'
              aria-pressed={value === true}
              active={value === true}
              onClick={(e) => handleChange(e, !value)}>
              <ArrowLeftIcon />
            </FlipButton>
          </Flex>
        </Flex>
      );
    }

    return (
      <SliderWrapper theme={theme}>
        <Slider
          key={`slider-${type}`}
          {...config}
          value={value as number}
          onChange={handleChange}
          valueLabelDisplay='auto'
          {...props}></Slider>
      </SliderWrapper>
    );
  };
  return <ThemeProvider theme={materialUItheme}>{renderKnob(type)}</ThemeProvider>;
};
