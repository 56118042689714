import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface UserFormState {
  isValid: boolean;
  formData: Record<string, unknown>;
}

const initialState: UserFormState = {
  isValid: false,
  formData: {},
};

export const userForm = createSlice({
  name: 'userForm',
  initialState: initialState,
  reducers: {
    updateFormData: (state: UserFormState, action: PayloadAction<Record<string, unknown>>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    setIsFormValid: (state: UserFormState, action: PayloadAction<boolean>) => {
      state.isValid = action.payload;
    },
  },
});

export const { updateFormData, setIsFormValid } = userForm.actions;

// Selectors
export const selectFormData = (state: RootState) => state.userForm.formData;

export default userForm.reducer;
