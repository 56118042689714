import styled from '@emotion/styled';
import React from 'react';
import { ErrorLabel, HelpTextLabel } from './Form.styles';

const InputContainer = styled.div<{ isRequired?: boolean }>(({ isRequired, theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    input: {
      fontSize: 16,
      padding: 6
    },

    label: {
      padding: '6px 0',
      display: 'block',
      ...theme.form.labels,

      ...(isRequired && {
        ':after': {
          content: '" *"',
          color: theme.form.error.color,
        },
      }),
    },

    span: {
      display: 'block',
    },

    '& input::placeholder': {
      ...theme.form.placeholders,
    },
  };
});

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  id: string;
  label?: string;
  error?: string;
  helpText?: string;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { label, id, error, helpText, required, ...nativeProps } = props;
  return (
    <InputContainer isRequired={required}>
      {label && (
        <label id={`input__label-${id}`} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        ref={ref}
        id={id}
        aria-labelledby={`input__label-${id}`}
        aria-invalid={error ? 'true' : 'false'}
        required={required}
        {...nativeProps}
      />

      {helpText && <HelpTextLabel>{helpText}</HelpTextLabel>}
      {error && <ErrorLabel role='alert'>{error}</ErrorLabel>}
    </InputContainer>
  );
});
