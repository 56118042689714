import { PymContext } from 'contexts/PymProvider';
import { useContext } from 'react';

export function usePym() {
  const { pym } = useContext(PymContext);

  return {
    pym,
  };
}

export default usePym;
