import styled from '@emotion/styled';
import { CheckmarkIcon } from 'components/icons';
import React from 'react';
import { ErrorLabel } from './Form.styles';

type CheckboxLabelProps = { isRequired?: boolean };

const CheckboxContainer = styled.label`
  cursor: pointer;
  display: inline-flex;
  align-items: start;
  ${(props) => props.theme.form.labels};

  & input {
    position: absolute;
    width: 1em;
    height: 1em;
    opacity: 0.00001;
  }

  & svg {
    width: 1.2em;
    height: 1.2em;
    margin-right: 0.5em;
    margin-top: 0.1em;
    flex: 0 0 auto;
  }

  & svg * {
    transition: all 0.1s linear;
  }

  & input[type='checkbox']:checked + svg {
    .cb-bg {
      fill: ${(props) => props.theme.form.checkboxes.checked_background};
      stroke: ${(props) => props.theme.form.checkboxes.checkmark_color};
    }

    .cb-cm {
      stroke: ${(props) => props.theme.form.checkboxes.checkmark_color};
    }
  }

  & input[type='checkbox']:focus + svg {
    outline: 3px solid ${(props) => props.theme.global.colors.primaryLight};
    outline-offset: 2px;
  }

  & a {
    color: ${(props) => props.theme.form.links.color};
  }
`;

const CheckboxLabel = styled.span<CheckboxLabelProps>((props) => {
  return {
    ...(props.isRequired && {
      ':after': {
        content: '" *"',
        color: props.theme.form.error.color,
      },
    }),
  };
});

interface CheckboxProps extends Omit<React.HTMLProps<HTMLInputElement>, 'type'> {
  label: string;
  error?: string;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { label, id, error, required, ...nativeProps } = props;

  return (
    <div>
      <CheckboxContainer htmlFor={id}>
        <input type='checkbox' required={required} ref={ref} id={id} name={id} {...nativeProps} />
        <CheckmarkIcon />

        <CheckboxLabel isRequired={required} dangerouslySetInnerHTML={{ __html: label }}></CheckboxLabel>
      </CheckboxContainer>
      {error && <ErrorLabel role='alert'>{error}</ErrorLabel>}
    </div>
  );
});
