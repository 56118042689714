// require and configure dotenv, will load vars in .env in PROCESS.ENV
const config = {
  env: process.env.NODE_ENV,
  main_wid: process.env.REACT_APP_DEFAULT_MAIN_WID || '',
  form_wid: process.env.REACT_APP_DEFAULT_FORM_WID || '',
  styles_wid: process.env.REACT_APP_DEFAULT_STYLES_WID || '',
  stickers_wid: process.env.REACT_APP_DEFAULT_STICKERS_WID || '',
  tailor: {
    env: process.env.REACT_APP_TAILOR_ENV || 'prod',
  },
};

export default config;
