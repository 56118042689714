type Breakpoint = 'mobile' | 'tablet' | 'desktop' | 'xl';

export const breakpoints: Record<string, number> = {
  mobile: 320,
  tablet: 768,
  desktop: 980,
  xl: 1200,
};

type MediaQuery = Record<Breakpoint, string>;

export const mq = Object.keys(breakpoints)
  .map((key) => [key, breakpoints[key]])
  .reduce((prev, [key, breakpoint]) => {
    prev[key as Breakpoint] = `@media (min-width: ${breakpoint}px)`;
    return prev;
  }, {} as MediaQuery);

export const loadFonts = (urls: string[]): void => {
  const unloadedUrls = urls.filter((url) => !document.querySelectorAll(`link[href='${url}']`).length);

  unloadedUrls.forEach((url) => {
    const linkEl = document.createElement('link');
    linkEl.rel = 'stylesheet';
    linkEl.href = url;
    document.head.appendChild(linkEl);
  });
};
