import { isTrue } from '@telescope/utility-helpers';
import { cloneDeep } from 'lodash-es';

export const parseValidations = (validations: Record<string, any>) => {
  const parsed = cloneDeep(validations);

  if ('required' in validations) {
    parsed.required.value = isTrue(validations.required.value);
  }
  if ('pattern' in validations) {
    parsed.pattern.value = new RegExp(validations.pattern.value);
  }

  return parsed;
};
