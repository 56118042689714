import styled from '@emotion/styled';
import { KnobType, TransformKnob } from 'components';
import { Accordion } from 'components/Accordion';
import { BrightnessIcon, ColorIcon, ContrastIcon, FlipIcon, MoveIcon, RotateIcon, ScaleIcon } from 'components/icons';
import { Flex } from 'components/layout';
import { useAppSelector, useCanvas, useFabricCore } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, selectUserImageTransformValues, update } from 'redux/reducers/user-image';
import { GACategory, mq } from 'utils';

interface ToggleButton {
  name: 'move' | 'scale' | 'rotate' | 'flip' | 'color' | 'brightness' | 'contrast';
  icon: React.ComponentType;
}

const toggleButtons: ToggleButton[] = [
  {
    name: 'move',
    icon: MoveIcon,
  },
  {
    name: 'scale',
    icon: ScaleIcon,
  },
  {
    name: 'rotate',
    icon: RotateIcon,
  },
  {
    name: 'flip',
    icon: FlipIcon,
  },
  {
    name: 'color',
    icon: ColorIcon,
  },
  {
    name: 'brightness',
    icon: BrightnessIcon,
  },
  {
    name: 'contrast',
    icon: ContrastIcon,
  },
];

const PhotoEditorContainer = styled.div((props) => {
  return {
    '.knob-controls': {
      marginBottom: 30,
    },
  };
});

const StyledToggleButton = styled.button<{ active?: boolean }>(({ theme, active }) => {
  const styles = theme.stylizer.edit_photo.buttons;
  const backgroundColor = active ? styles.active.background : styles.background;
  const textColor = active ? styles.active.color : styles.color;
  const textShadow = active ? styles.active.textShadow : styles.textShadow;

  return {
    cursor: 'pointer',
    color: textColor,
    fontWeight: 'bold',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: backgroundColor,
    border: '1px solid #E1E1E1',
    boxShadow: '2px 2px 6px 0 rgba(0,0,0,0.10)',
    borderRadius: 4,
    width: 63,
    height: 63,
    textShadow: textShadow,

    i: {
      marginBottom: 5,
    },

    svg: {
      width: 18,
      height: 18,
    },

    'svg path': {
      fill: textColor,
      textShadow: textShadow,
    },

    ':disabled': {
      cursor: 'default',
      opacity: 0.75,
    },

    ':hover:enabled, :active:enabled': {
      background: styles.active.background,
      color: styles.active.color,
      textShadow: styles.active.textShadow
    },

    ':hover:enabled svg path, :active:enabled svg path': {
      fill: styles.active.color,
    },

    [mq.desktop]: {
      width: 70,
      height: 70,
    },
  };
});

export const PhotoEditor: FC = () => {
  const copy = useAppSelector((state) => state.widget.mainWidget.text.stylizer.edit_photo);
  const { userImage, canvas } = useCanvas();
  const [activeControl, setActiveControl] = useState<KnobType | 'move'>('move');
  const { applyFilter } = useFabricCore();
  const dispatch = useDispatch();
  const imageValues = useSelector(selectUserImageTransformValues);
  const canEdit = userImage !== null;

  // Set event listeners when image changes, and reset image values
  useEffect(() => {
    if (userImage) {
      userImage.on('moving', () => dispatch(update({ moveX: userImage.left || 0, moveY: userImage.top || 0 })));
      userImage.on('scaling', () => dispatch(update({ scale: userImage.getScaledWidth() / (canvas?.width ?? 0) })));
      userImage.on('rotating', () => dispatch(update({ rotate: userImage.angle || 0 })));
      dispatch(reset());
    }
  }, [userImage, dispatch, canvas?.width]);

  const handleChange = (event: any, type: KnobType, value: any) => {
    if (userImage) {
      switch (type) {
        case 'moveX':
          userImage.set('left', value);
          break;
        case 'moveY':
          userImage.set('top', value);
          break;
        case 'scale':
          userImage.scaleToWidth(value * (canvas?.width ?? 1));
          break;
        case 'rotate':
          userImage.rotate(value);
          break;
        case 'flip':
          userImage.set('flipX', value);
          break;
        case 'color':
          applyFilter('saturation', userImage, value);
          break;
        case 'brightness':
          applyFilter('brightness', userImage, value);
          break;
        case 'contrast':
          applyFilter('contrast', userImage, value);
          break;
      }

      userImage.setCoords();
      dispatch(update({ [type]: value }));
    }
  };

  const renderActiveKnob = () => {
    if (activeControl === 'move') {
      const width = canvas?.width || 0;
      const height = canvas?.height || 0;
      return (
        <Flex>
          <TransformKnob
            type={'moveX'}
            onChange={handleChange}
            value={imageValues['moveX']}
            min={-width}
            max={width}
            marks={[
              { label: `-${width}`, value: -width },
              { label: '0', value: 0 },
              { label: width, value: width },
            ]}></TransformKnob>
          <TransformKnob
            type={'moveY'}
            onChange={handleChange}
            value={imageValues['moveY']}
            min={-height}
            max={height}
            marks={[
              { label: `-${height}`, value: -height },
              { label: '0', value: 0 },
              { label: height, value: height },
            ]}></TransformKnob>
        </Flex>
      );
    }

    return (
      <TransformKnob type={activeControl} onChange={handleChange} value={imageValues[activeControl]}></TransformKnob>
    );
  };

  const renderKnobToggleButtons = () => {
    return toggleButtons.map((button) => {
      const { name } = button;

      return (
        <StyledToggleButton
          key={name}
          data-ga-category={GACategory.EDIT}
          onClick={() => setActiveControl(name)}
          aria-pressed={activeControl === name}
          active={activeControl === name}>
          <i>
            <button.icon />
          </i>
          {copy[button.name].button_text}
        </StyledToggleButton>
      );
    });
  };

  return (
    <Accordion title={copy.headline} disabled={!canEdit} openInitially={canEdit}>
      {canEdit && (
        <PhotoEditorContainer>
          <p dangerouslySetInnerHTML={{ __html: copy.description }} />
          <Flex className='knob-controls' horizontal wrap gap={10}>
            {renderKnobToggleButtons()}
          </Flex>

          <p dangerouslySetInnerHTML={{ __html: copy[activeControl].help_text }}></p>
          {renderActiveKnob()}
        </PhotoEditorContainer>
      )}
    </Accordion>
  );
};
