import { configureStore } from '@reduxjs/toolkit';
import userImageReducer from 'redux/reducers/user-image';
import widgetReducer from 'redux/reducers/widget';
import activeStickerReducer from './reducers/active-sticker';
import userFormReducer from './reducers/user-form';

export const store = configureStore({
  reducer: {
    widget: widgetReducer,
    userImageTransformValues: userImageReducer,
    activeStickerTransformValues: activeStickerReducer,
    userForm: userFormReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
