import styled from '@emotion/styled';
import { useAppSelector } from 'hooks';
import { breakpoints } from 'utils';

const AbbreviatedRules = styled.p(() => {
  return {
    fontSize: '11px',
    padding: '10px',
    textAlign: 'center',
    maxWidth: 600,
    margin: 'auto'
  };
});

export const Rules = () => {
  const rules = useAppSelector((state) => state.widget.mainWidget.text.rules);

  if (!rules?.abbreviated_rules) return null;

  return (
    <AbbreviatedRules className="rules">{rules.abbreviated_rules}</AbbreviatedRules>
  )
}