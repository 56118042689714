/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { FC } from 'react';
import { Alignment, styles } from './Flex.styles';

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  inline?: boolean;
  horizontal?: boolean;
  gap?: number | string;
  wrap?: boolean;
  reversed?: boolean;
  horizontalAlign?: Alignment;
  verticalAlign?: Alignment;
  padding?: number | string;
}

export const Flex: FC<FlexProps> = ({
  inline,
  horizontal,
  gap,
  reversed,
  horizontalAlign,
  verticalAlign,
  children,
  wrap,
  padding,
  ...props
}) => {
  const style: { root: any; inner?: any } = styles({
    inline,
    horizontal,
    gap,
    reversed,
    horizontalAlign,
    verticalAlign,
    wrap,
    padding,
  });

  if (wrap) {
    return (
      <div css={css(style.root)} {...props}>
        <div css={css(style.inner)}>{children}</div>
      </div>
    );
  }

  return (
    <div css={css(style.root)} {...props}>
      {children}
    </div>
  );
};
