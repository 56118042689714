import { parseGap } from './Flex.utils';

export type Alignment =
  | 'start'
  | 'end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'baseline'
  | 'stretch';

const nameMap: { [key: string]: string } = {
  start: 'flex-start',
  end: 'flex-end',
};

export interface FlexParentProps {
  inline?: boolean;
  horizontal?: boolean;
  gap?: number | string;
  wrap?: boolean;
  reversed?: boolean;
  horizontalAlign?: Alignment;
  verticalAlign?: Alignment;
  verticalFill?: boolean;
  padding?: number | string;
}

export const styles = ({
  inline,
  horizontal,
  gap,
  wrap,
  reversed,
  horizontalAlign,
  verticalAlign,
  verticalFill,
  padding,
}: FlexParentProps) => {
  const { rowGap, columnGap } = parseGap(gap ?? 0);
  const horizontalMargin = `${-0.5 * columnGap.value}${columnGap.unit}`;
  const verticalMargin = `${-0.5 * rowGap.value}${rowGap.unit}`;

  if (wrap) {
    return {
      root: {
        display: inline ? 'inline-flex' : 'flex',
        flexDirection: horizontal ? (reversed ? 'row-reverse' : 'row') : reversed ? 'column-reverse' : 'column',
        flexWrap: 'wrap',
        ...(horizontalAlign && {
          [horizontal ? 'justifyContent' : 'alignItems']: nameMap[horizontalAlign] || horizontalAlign,
        }),
        ...(verticalAlign && {
          [horizontal ? 'alignItems' : 'justifyContent']: nameMap[verticalAlign] || verticalAlign,
        }),
        width: 'auto',
        height: '100%',
        ...(horizontal && {
          height: verticalFill ? '100%' : 'auto',
        }),

        '& > *:not(:first-child)': {
          ...(horizontal
            ? { marginLeft: `${columnGap.value}${columnGap.unit}` }
            : { marginTop: `${rowGap.value}${rowGap.unit}` }),
        },
      },
      inner: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: horizontalMargin,
        marginRight: horizontalMargin,
        marginTop: verticalMargin,
        marginBottom: verticalMargin,
        overflow: 'visible',
        boxSizing: 'border-box',
        padding,
        // avoid unnecessary calc() calls if horizontal gap is 0
        width: columnGap.value === 0 ? '100%' : `calc(100% + ${columnGap.value}${columnGap.unit})`,
        maxWidth: '100vw',
        ...(horizontalAlign && {
          [horizontal ? 'justifyContent' : 'alignItems']: nameMap[horizontalAlign] || horizontalAlign,
        }),
        ...(verticalAlign && {
          [horizontal ? 'alignItems' : 'justifyContent']: nameMap[verticalAlign] || verticalAlign,
        }),
        ...(horizontal && {
          flexDirection: reversed ? 'row-reverse' : 'row',

          // avoid unnecessary calc() calls if vertical gap is 0
          height: rowGap.value === 0 ? '100%' : `calc(100% + ${rowGap.value}${rowGap.unit})`,

          '& > *': {
            margin: `${0.5 * rowGap.value}${rowGap.unit} ${0.5 * columnGap.value}${columnGap.unit}`,
            maxWidth: columnGap.value === 0 ? '100%' : `calc(100% - ${columnGap.value}${columnGap.unit})`,
          },
        }),

        ...(!horizontal && {
          flexDirection: reversed ? 'column-reverse' : 'column',
          height: `calc(100% + ${rowGap.value}${rowGap.unit})`,

          '& > *': {
            margin: `${0.5 * rowGap.value}${rowGap.unit} ${0.5 * columnGap.value}${columnGap.unit}`,
            maxHeight: rowGap.value === 0 ? '100%' : `calc(100% - ${rowGap.value}${rowGap.unit})`,
          },
        }),
      },
    };
  }

  return {
    root: {
      display: inline ? 'inline-flex' : 'flex',
      flexDirection: horizontal ? (reversed ? 'row-reverse' : 'row') : reversed ? 'column-reverse' : 'column',
      flexWrap: 'nowrap',
      ...(horizontalAlign && {
        [horizontal ? 'justifyContent' : 'alignItems']: nameMap[horizontalAlign] || horizontalAlign,
      }),
      ...(verticalAlign && {
        [horizontal ? 'alignItems' : 'justifyContent']: nameMap[verticalAlign] || verticalAlign,
      }),
      width: 'auto',
      height: verticalFill ? '100%' : 'auto',
      boxSizing: 'border-box',
      padding,

      [`& > *:not(${reversed ? ':last-child' : ':first-child'})`]: {
        ...(horizontal
          ? { marginLeft: `${columnGap.value}${columnGap.unit}` }
          : { marginTop: `${rowGap.value}${rowGap.unit}` }),
      },
    },
  };
};
