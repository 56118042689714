import { getQSPs } from '@telescope/utility-helpers';
import { Child } from 'pym.js';
import { createContext, FC } from 'react';
import { DOMUtil } from 'utils';

const PYM_PARENT_ID = 'telescope-app';

/**
 * Creates a new pymChild instance if the app is iframed.
 * @returns pymChild if inside an iframe, null otherwise
 */
const loadPym = () => {
  if (DOMUtil.isIframed()) {
    const { childId } = getQSPs();
    const pymParentId = childId || PYM_PARENT_ID;
    const pymChild = new Child({
      polling: 500,
      id: pymParentId,
    });

    return pymChild;
  } else {
    return null;
  }
};

interface PymState {
  pym: any | null;
}

export const PymContext = createContext<PymState>({
  pym: null,
});

interface PymProviderProps {
  children: React.ReactNode
}

export const PymProvider: FC<PymProviderProps> = ({ children }) => {
  const pym = loadPym();
  const context = {
    pym,
  };

  return <PymContext.Provider value={context}>{children}</PymContext.Provider>;
};
