import * as React from 'react';

export const DeleteIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width={20} height={23} viewBox='0 0 20 23' xmlns='http://www.w3.org/2000/svg' ref={svgRef} {...props}>
      <defs>
        <filter colorInterpolationFilters='auto' id='prefix__a'>
          <feColorMatrix
            in='SourceGraphic'
            values='0 0 0 0 0.152941 0 0 0 0 0.356863 0 0 0 0 0.431373 0 0 0 1.000000 0'
          />
        </filter>
      </defs>
      <g transform='translate(-19 -382)' filter='url(#prefix__a)' fill='none' fillRule='evenodd'>
        <g fill='#000' fillRule='nonzero'>
          <path d='M37 384.695h-4v-.674A2.013 2.013 0 0031 382h-4c-1.103 0-2 .907-2 2.021v.674h-4c-1.103 0-2 .907-2 2.022v1.347a.67.67 0 00.667.674h.719l1.173 14.418v.006A1.999 1.999 0 0023.551 405h10.898c1.041 0 1.897-.79 1.992-1.838v-.006l1.173-14.418h.72a.67.67 0 00.666-.674v-1.347a2.013 2.013 0 00-2-2.022zm-10.667-.674c0-.371.3-.673.667-.673h4c.368 0 .667.302.667.673v.674h-5.334v-.674zm8.78 19.02a.666.666 0 01-.664.611H23.551a.666.666 0 01-.664-.61l-1.163-14.304h14.552l-1.163 14.304zm2.554-15.65H20.333v-.674c0-.372.3-.674.667-.674h16c.368 0 .667.302.667.674v.674z' />
          <path d='M29 390c-.552 0-1 .305-1 .682v9.636c0 .377.448.682 1 .682s1-.305 1-.682v-9.636c0-.377-.448-.682-1-.682zM33 390c-.552 0-1 .305-1 .682v9.636c0 .377.448.682 1 .682s1-.305 1-.682v-9.636c0-.377-.448-.682-1-.682zM25 390c-.552 0-1 .305-1 .682v9.636c0 .377.448.682 1 .682s1-.305 1-.682v-9.636c0-.377-.448-.682-1-.682z' />
        </g>
      </g>
    </svg>
  );
});

export const CameraIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg
      width='1.2em'
      height='1.2em'
      fill='currentColor'
      viewBox='0 0 22 17'
      xmlns='http://www.w3.org/2000/svg'
      ref={svgRef}
      {...props}>
      <defs>
        <filter colorInterpolationFilters='auto' id='prefix__a'>
          <feColorMatrix
            in='SourceGraphic'
            values='0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0'
          />
        </filter>
      </defs>
      <g transform='translate(-12 -11)' filter='url(#prefix__a)' fill='none' fillRule='evenodd'>
        <path
          d='M26.555 20.992c0 1.835-1.505 3.328-3.355 3.328s-3.354-1.493-3.354-3.328c0-1.835 1.504-3.327 3.354-3.327s3.355 1.493 3.355 3.327zm7.045-4.725v9.451A2.29 2.29 0 0131.3 28H15.1a2.29 2.29 0 01-2.3-2.282v-9.45a2.29 2.29 0 012.3-2.282h2.829v-.79c0-1.102.9-1.996 2.012-1.996h6.518c1.112 0 2.012.894 2.012 1.996v.79H31.3c1.27 0 2.3 1.021 2.3 2.281zm-5.32 4.725c0-2.778-2.28-5.038-5.08-5.038-2.8 0-5.079 2.26-5.079 5.038 0 2.779 2.279 5.039 5.079 5.039s5.08-2.26 5.08-5.039z'
          fill='#000'
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
});

export const UploadIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg
      id='prefix__Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      width='1.2em'
      height='1.2em'
      fill='currentColor'
      ref={svgRef}
      {...props}>
      <path
        className='prefix__st0'
        d='M359.9 144L266.1 36.8c-2.5-2.9-6.2-4.6-10.1-4.6-3.9 0-7.5 1.7-10.1 4.6L152.1 144c-3.5 4-4.3 9.6-2.1 14.4 2.2 4.8 6.9 7.9 12.2 7.9h53.6v174.2c0 7.4 6 13.4 13.4 13.4h53.6c7.4 0 13.4-6 13.4-13.4V166.2h53.6c5.3 0 10-3.1 12.2-7.9s1.4-10.4-2.1-14.3z'
      />
      <path
        className='prefix__st0'
        d='M420.2 321.8v89.6H91.8v-89.6H32v119.5c0 16.5 13.4 29.9 29.9 29.9h388.2c16.5 0 29.9-13.3 29.9-29.9V321.8h-59.8z'
      />
    </svg>
  );
});

export const MoveIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg' ref={svgRef} {...props}>
      <path
        fill='#000'
        d='M40.003 0L27.3 12.703l4.673 4.673 4.727-4.727v24.04H12.67l4.716-4.705-4.678-4.673L0 40.013l12.708 12.708 4.678-4.673-4.748-4.743H36.7v24.04l-4.727-4.726-4.673 4.673L40.003 80l12.702-12.708-4.673-4.673-4.727 4.727v-24.04h24.057l-4.743 4.742 4.673 4.673L80 40.013 67.292 27.311l-4.673 4.673 4.716 4.705h-24.03V12.65l4.727 4.727 4.673-4.673z'
        fillRule='evenodd'
      />
    </svg>
  );
});

export const ScaleIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 79 80' xmlns='http://www.w3.org/2000/svg' ref={svgRef} {...props}>
      <g fill='#000' fillRule='evenodd'>
        <path d='M55.99.987v7.105l10.249.006-17.157 17.157 5.023 5.023L71.3 13.092v10.311h7.106V.987zM7.106 67.293V56.97l-7.105.006v22.417h22.416v-7.106H12.163l17.162-17.162-5.028-5.029zM48.542 30.817a3.556 3.556 0 010 5.028 3.563 3.563 0 01-5.034 0 3.556 3.556 0 010-5.028 3.563 3.563 0 015.034 0M41.674 37.684a3.556 3.556 0 010 5.028 3.563 3.563 0 01-5.034 0 3.556 3.556 0 010-5.028 3.553 3.553 0 015.034 0M34.807 44.548a3.563 3.563 0 010 5.035 3.563 3.563 0 01-5.034 0 3.563 3.563 0 010-5.035 3.563 3.563 0 015.034 0' />
      </g>
    </svg>
  );
});

export const RotateIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 77 80'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      ref={svgRef}
      {...props}>
      <defs>
        <path id='prefix__a' d='M76.522.002H0v79.284h76.522V.002z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b' fill='#fff'>
          <use xlinkHref='#prefix__a' />
        </mask>
        <path
          d='M58.305 58.361c-10.337 10.305-27.074 10.278-37.379-.06-10.305-10.331-10.278-27.068.054-37.379 10.327-10.288 27.031-10.277 37.342.027l-9.879 9.846 28.036.038.043-28.03-8.84 8.814c-15.458-15.468-40.534-15.495-56.03-.049-15.505 15.457-15.543 40.56-.086 56.066 15.458 15.5 40.56 15.544 56.067.081l-9.328-9.354z'
          fill='#000'
        />
      </g>
    </svg>
  );
});

export const FlipIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 77 79'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      ref={svgRef}
      {...props}>
      <defs>
        <path id='prefix__a' d='M0 79.927h76.425V1.23H0z' />
      </defs>
      <g transform='translate(0 -1)' fill='none' fillRule='evenodd'>
        <path
          d='M75.426 11.875c.598.223.997.798.997 1.44v54.523c0 .647-.399 1.221-.997 1.445a1.518 1.518 0 01-1.684-.44l-23.3-27.262a1.549 1.549 0 010-2.01l23.295-27.255a1.512 1.512 0 011.69-.44zM2.682 12.314a1.526 1.526 0 00-1.689-.44 1.534 1.534 0 00-.991 1.444v54.523c0 .642.393 1.216.991 1.44a1.518 1.518 0 001.69-.44l23.3-27.263a1.555 1.555 0 000-2.003l-23.3-27.261z'
          fill='#000'
        />
        <mask id='prefix__b' fill='#fff'>
          <use xlinkHref='#prefix__a' />
        </mask>
        <path
          fill='#000'
          d='M36.144 12.477h4.132V1.234h-4.132zM36.144 34.957h4.132V23.714h-4.132zM36.144 57.442h4.132V46.199h-4.132zM36.144 79.927h4.132V68.685h-4.132z'
        />
      </g>
    </svg>
  );
});

export const ColorIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 80 80'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      ref={svgRef}
      {...props}>
      <defs>
        <path id='prefix__a' d='M79.528 79.53H0V0h79.528v79.53z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <mask id='prefix__b' fill='#fff'>
          <use xlinkHref='#prefix__a' />
        </mask>
        <path
          d='M15.247 73.99l-10.482.778.772-10.487 36.64-36.641 9.71 9.71-36.64 36.64zM76.176 3.357c-4.49-4.49-11.72-4.472-16.204.018l-8.079 8.078-1.625-1.63c-3.578-3.572-9.37-3.566-12.948.011-3.59 3.59-3.566 9.377.023 12.972l1.602 1.595L1.708 61.638a2.279 2.279 0 00-.666 1.45L.007 77.071a2.291 2.291 0 002.45 2.455l13.988-1.035a2.354 2.354 0 001.45-.666l37.23-37.237 1.602 1.602c3.584 3.583 9.4 3.595 12.972.017 3.59-3.583 3.59-9.364.012-12.936l-1.626-1.631 8.073-8.079c4.49-4.483 4.49-11.726.018-16.204z'
          fill='#000'
        />
      </g>
    </svg>
  );
});

export const BrightnessIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg' ref={svgRef} {...props}>
      <path
        d='M40.003 61.744c-12.011 0-21.747-9.736-21.747-21.747 0-12.01 9.736-21.741 21.747-21.741 12.01 0 21.741 9.73 21.741 21.741s-9.73 21.747-21.741 21.747m36-25.748H67.89a27.965 27.965 0 00-5.337-12.893l5.734-5.734a4.002 4.002 0 000-5.656 4.002 4.002 0 00-5.656 0l-5.729 5.734a28.049 28.049 0 00-12.898-5.337V3.997c0-2.202-1.8-3.997-4.001-3.997A4.005 4.005 0 0036 3.997v8.113a28.072 28.072 0 00-12.898 5.337l-5.729-5.734a4.002 4.002 0 00-5.655 0 4.002 4.002 0 000 5.656l5.734 5.734a27.958 27.958 0 00-5.343 12.893H4.002A4.01 4.01 0 000 39.997 4.006 4.006 0 004.002 44h8.108a28.023 28.023 0 005.343 12.898l-5.734 5.729a4.002 4.002 0 000 5.655 4.002 4.002 0 005.655 0l5.73-5.728C26.801 65.323 31.2 67.212 36 67.89v8.108A4.006 4.006 0 0040.003 80a4.01 4.01 0 004.001-4.002V67.89a28.118 28.118 0 0012.898-5.337l5.729 5.728a4.002 4.002 0 005.656 0 4.002 4.002 0 000-5.655l-5.734-5.73A27.892 27.892 0 0067.89 44h8.113a4.01 4.01 0 004.002-4.002c0-2.201-1.8-4.001-4.002-4.001'
        fill='#000'
        fillRule='evenodd'
      />
    </svg>
  );
});

export const ContrastIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 80 80' xmlns='http://www.w3.org/2000/svg' ref={svgRef} {...props}>
      <path
        d='M40.003 4.707c19.515 0 35.29 15.775 35.29 35.29 0 19.521-15.775 35.296-35.29 35.296V4.707zm0-4.707C17.938 0 0 17.932 0 39.997S17.938 80 40.003 80C62.062 80 80 62.062 80 39.997S62.062 0 40.003 0z'
        fill='#000'
        fillRule='evenodd'
      />
    </svg>
  );
});

export const ArrowLeftIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width={43} height={88} viewBox='0 0 43 88' xmlns='http://www.w3.org/2000/svg' ref={svgRef} {...props}>
      <path
        fill='#221F20'
        d='M36.93 0L.514 43.469l.01.011-.524.626 36.416 43.47 5.896-7.04-30.529-36.441L42.827 7.038 36.932 0z'
        fillRule='evenodd'
      />
    </svg>
  );
});

export const ArrowRightIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width={42} height={86} viewBox='0 0 42 86' xmlns='http://www.w3.org/2000/svg' ref={svgRef} {...props}>
      <path
        fill='#221F20'
        d='M5.79 0L0 6.91l30.485 36.392L.505 79.087 6.296 86l35.762-42.687-.515-.614.01-.012L5.79 0z'
        fillRule='evenodd'
      />
    </svg>
  );
});

export const CheckmarkIcon = React.forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props, svgRef) => {
  return (
    <svg width={32} height={32} viewBox='-4 -4 39 39' aria-hidden='true' ref={svgRef} {...props}>
      <rect
        className='cb-bg'
        width={35}
        height={35}
        x={-2}
        y={-2}
        stroke='currentColor'
        fill='none'
        strokeWidth={3}
        rx={6}
        ry={6}
      />
      <path className='cb-cm' stroke='transparent' strokeWidth={4} fill='none' d='M4 14l8 9L28 5' />
    </svg>
  );
});
