/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import useCMS from '@telescope/react-hooks/useCMS';
import { isTrue } from '@telescope/utility-helpers';
import { Button, Form, FrameSelection, ImageCapture, PhotoEditor, Share, Stage, StickerEditor, TextEditor } from 'components';
import { useAppDispatch, useAppSelector, useCanvas, useFabricCore, useScroll } from 'hooks';
import { isEmpty } from 'lodash-es';
import { FormWidgetData } from 'models';
import { FC, useCallback, useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { selectFormData, setIsFormValid, updateFormData } from 'redux/reducers/user-form';
import { reset as resetUserImage } from 'redux/reducers/user-image';
import { GAPage, mq, trackGooglePage } from 'utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const MainContainer = styled.main((props) => {
  return {
    padding: '0 10px 25px',
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    gridGap: 15,
    justifyItems: 'center',
    
    '& > section': {
      width: '100%',
      justifyContent: 'center',
      display: 'flex'
    },

    '& > section > *': {
      width: '100%',
    },

    '.frame-selection': {
      width: 'auto'
    },

    '.image-capture': {
      textAlign: 'center'
    },

    '.stage-section ': {
      display: 'block',
      width: 'auto'
    },
    
    '.stage-section.finalized .upper-canvas': {
      pointerEvents: 'none'
    },

    [mq.desktop]: {
      maxWidth: 980,
      width: 980,
      gridTemplateColumns: '555px 1fr',
      gridTemplateAreas: '\
        "stage frame" \
        "stage image" \
        "stage photo" \
        "stage sticker" \
        "stage btn" \
        "form ." \
      ',
      gridAutoRows: 'auto auto auto auto minmax(0, 1fr)',

      '.frame-selection': {
        width: '100%',
        gridArea: 'frame'
      },
  
      '.image-capture': {
        gridArea: 'image'
      },
  
      '.stage-section ': {
        width: '100%',
        gridArea: 'stage'
      },

      '.photo-editor': {
        gridArea: 'photo'
      },
  
      '.sticker-editor': {
        gridArea: 'sticker'
      },
  
      '.form': {
        gridArea: 'form'
      },
  
      '.finalize-btn': {
        gridArea: 'btn'
      }
    },
  };
});

export const Main: FC = () => {
  const copy = useAppSelector((state) => state.widget.mainWidget.text.stylizer);
  const { form_wid, form_poll_rate } = useAppSelector((state) => state.widget.mainWidget.text.app_settings);
  const { canvas, userImage, setActiveSticker, setActiveObject, setFrame, setUserImage } = useCanvas();
  const [finalizedImage, setFinalizedImage] = useState<string | null>(null);
  const { exportCanvasAsImage, lockCanvas, unlockCanvas } = useFabricCore();
  const dispatch = useAppDispatch();
  const formCompleted = useAppSelector((state) => state.userForm.isValid);
  const [ textCompleted, setTextCompleted ] = useState(false);
  const canEdit = userImage !== null; // Must upload an image before you can edit
  const canFinalize = canEdit && (formCompleted || !form_wid) && textCompleted; // Must upload an image and have a valid form (if form is enabled)
  const stickersEnabled = isTrue(copy.edit_stickers.display);
  const [formWidget] = useCMS<FormWidgetData>(form_wid, { pollingFrequency: Number(form_poll_rate) * 1000 });
  const { scrollToTop } = useScroll();
  const formValues = useAppSelector(selectFormData, shallowEqual);

  const isDesktop = useMediaQuery('(min-width:980px)');

  useEffect(() => {
    trackGooglePage(GAPage.LANDING_PAGE);
  }, []);

  /**
   * Clear any stickers, and remove any transforms/filters from user image.
   * Unlock the canvas to resume editing
   */
  const handleReset = () => {
    if (canvas) {
      unlockCanvas();
      canvas.clear();
    }

    dispatch(resetUserImage());
    setUserImage(null);
    setFrame(null);
    setFinalizedImage(null);
    scrollToTop();
  };

  /**
   * Rasterize the canvas to a dataURL and lock the canvas
   */
  const onFinalizeImage = useCallback(() => {
    const zoom = canvas?.getZoom() || 1;
    const image = exportCanvasAsImage({ multiplier: 1 / zoom });
    setFinalizedImage(image ?? null);
    lockCanvas();
    setActiveObject(null);
    setActiveSticker(null);
    scrollToTop();
  }, [canvas]);

  const handleFormChange = (formValues: Record<string, unknown>) => {
    dispatch(updateFormData(formValues));
  };

  const handleFormValidChange = (isValid: boolean) => {
    dispatch(setIsFormValid(isValid));
  };

  const handleValidTextChange = (isValid: boolean) => {
    setTextCompleted(isValid)
  }

  const renderForm = () => {
    if (isEmpty(formWidget) || finalizedImage) return;

    return (
      <Form
        formFields={formWidget.text.form_fields}
        formData={formValues}
        onChange={handleFormChange}
        onValidChange={handleFormValidChange}
      />
    );
  };

  return (
    <MainContainer>
      {!finalizedImage && <>
        <section className="frame-selection"><FrameSelection /></section>
        <section className="image-capture"><ImageCapture /></section>
      </>}

      <section className={`stage-section ${!!finalizedImage ? 'finalized' : ''}`}>
        <Stage></Stage>
        { isDesktop && <section className="form">{renderForm()}</section> }
      </section>

      {!finalizedImage && <>
        <section className="photo-editor"><PhotoEditor /></section>
        { stickersEnabled && <section className="sticker-editor"><StickerEditor /></section> }
        <section className="text-editor"><TextEditor onValid={handleValidTextChange} /></section>
        { !isDesktop && <section className="form">{renderForm()}</section> }

        <div className="finalize-btn">
          <Button className='finalize-image-btn' isDisabled={!canFinalize} onClick={onFinalizeImage}>
            {copy.complete_image.button_complete}
          </Button>
        </div> 
      </> }

      {finalizedImage && <Share imageUrl={finalizedImage} onCreateAnother={handleReset}></Share>}
    </MainContainer>  
  )
}