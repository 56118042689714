import { useCallback } from 'react';
import usePym from './usePym';

export const useScroll = () => {
  const { pym } = usePym();

  const scrollToTop = useCallback(() => {
    if (pym) {
      pym.sendMessage('scrollToChildPosition', JSON.stringify({ x: 0, y: 0 }));
    } else {
      window.scrollTo(0, 0);
    }
  }, [pym]);

  return {
    scrollToTop,
  };
};
