import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Accordion } from 'components/Accordion';
import { useAppSelector, useCanvas, useMediaQuery, useFabricCore } from 'hooks';
import { FrameOption } from 'models';
import { FC, useCallback, useEffect, useState } from 'react';
import { GACategory, mq } from 'utils';

export const FrameThumbnail = styled.button(
  (props: { isSelected: boolean, theme?: Record<string, any> }) => css`
  width: 89px;
  height: 89px;
  border: ${props.isSelected ? `2px solid ${props.theme?.stylizer.select_frame.selected.borderColor || 'blue'}` : 'none'};

  & img {
    max-height: 100%;
    max-width: 100%;
    display: block;
  }
`
);

const MobileContainer = styled.div(({ theme }) => {
  const { mobile_headline, mobile_description } = theme.stylizer.select_frame;
  return {
    h3: {
      textAlign: 'center',
      marginBottom: 15,
      ...mobile_headline,
    },

    p: {
      marginBottom: 20,
      fontSize: 14,
      ...mobile_description,
    },
  };
});

export const FrameContainer = styled.div(({ theme }) => {
  const gap = '10px';

  return {
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    msOverflowStyle: '-ms-autohiding-scrollbar',
    margin: `calc(-1 * ${gap}) 0 0 calc(-1 * ${gap})`,
    width: `calc(100% + ${gap})`,
    maxWidth: `calc(100vw - 20px)`,

    '& ::-webkit-scrollbar': {
      display: 'none',
    },

    '& > *': {
      flex: '0 0 auto',
      margin: `${gap} 0 0 ${gap}`,
    },

    [mq.desktop]: {
      flexWrap: 'wrap',
    },
  };
});

export const FrameSelection: FC = () => {
  const { canvas, frame, setFrame } = useCanvas();
  const frameList = useAppSelector((state) => state.widget.mainWidget.data);
  const copy = useAppSelector((state) => state.widget.mainWidget.text.stylizer.select_frame);
  const [selectedFrame, setSelectedFrame] = useState(0);
  const isMobile = useMediaQuery(['(min-width: 980px)'], [false], true);
  const { addImage } = useFabricCore();
  const [ img, setImg ] = useState<fabric.Image | null>(null);

  /**
   * Updates the frame on the canvas when a new frame is selected.
   * Only one frame can be active at a time, so the previous frame is removed
   */
  const updateFrame = useCallback(
    async (selectedFrame: FrameOption) => {
      if (img) {
        img.setSrc(selectedFrame.frame_img_url, function (i: fabric.Image) {
          i.scaleToWidth(canvas?.width ?? 1);
          i.scaleToHeight(canvas?.height ?? 1);
          canvas?.renderAll();
        }, { width: canvas?.getWidth(), height: canvas?.getHeight(), crossOrigin: 'anonymous' });

      } else {
        const image = await addImage(selectedFrame.frame_img_url, {
          originX: 'left',
          originY: 'top',
          crossOrigin: 'anonymous',
          selectable: false,
          hasControls: false,
          evented: false
        })
  
        setImg(image);
      }

      canvas?.renderAll()
      setFrame(selectedFrame);
    },
    [canvas, setFrame, img]
  );

  const handleFrameSelection = useCallback(
    (frameIndex: number) => {
      setSelectedFrame(frameIndex);
      updateFrame(frameList[frameIndex]);
    },
    [frameList, updateFrame]
  );

  useEffect(() => {
    if (canvas && !frame) {
      // Set initial Frame
      handleFrameSelection(0);
    }
  }, [handleFrameSelection, canvas, frame]);

  const availableFrames = frameList.map((frame, index) => {
    return (
      <FrameThumbnail
        data-ga-category={GACategory.FRAME}
        data-ga-label={frame.frame_name}
        key={index}
        onClick={() => handleFrameSelection(index)}
        isSelected={selectedFrame === index}
        aria-pressed={selectedFrame === index}
        aria-label={frame.aria_label}>
        <img crossOrigin='anonymous' src={frame.frame_img_url} alt='' />
      </FrameThumbnail>
    );
  });

  if (isMobile) {
    return (
      <MobileContainer>
        <h3>{copy.headline}</h3>
        <p dangerouslySetInnerHTML={{ __html: copy.description }} />
        <FrameContainer>{availableFrames}</FrameContainer>
      </MobileContainer>
    );
  }

  return (
    <Accordion title={copy.headline} openInitially>
      <p dangerouslySetInnerHTML={{ __html: copy.description }} />
      <FrameContainer>{availableFrames}</FrameContainer>
    </Accordion>
  );
};
