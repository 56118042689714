export enum KeyCode {
  END = 'End',
  ENTER = 'Enter',
  HOME = 'Home',
  SPACE = ' ',
  SPACE_DEPRECATED = 'Spacebar',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
}

export class KeyboardUtil {
  public static isSpace(keyCode: KeyCode): boolean {
    return [KeyCode.SPACE, KeyCode.SPACE_DEPRECATED].includes(keyCode);
  }

  public static isEnter(keyCode: KeyCode): boolean {
    return keyCode === KeyCode.ENTER;
  }
}
