import styled from '@emotion/styled';
import { Accordion, FrameSelection, ImageCapture, PhotoEditor, StickerEditor } from 'components';
import { Button } from 'components/Button/Button';
import { useCanvas } from 'hooks';
import { FC } from 'react';

const StylizerContainer = styled.div`
  width: 500px;

  @media (min-width: 980px) {
    width: 350px;
    flex-grow: 0;

    & > * {
      margin-bottom: 20px;
    }
  }
`;

export const Stylizer: FC = () => {
  const { userImage } = useCanvas();

  const onFinalizeImage = () => {};

  return (
    <StylizerContainer>
      <FrameSelection></FrameSelection>
      <ImageCapture></ImageCapture>
      <PhotoEditor></PhotoEditor>

      <Accordion title='Add Stickers' disabled={!userImage}>
        <StickerEditor></StickerEditor>
      </Accordion>
      <Button isDisabled={!!userImage} onClick={onFinalizeImage}>
        Complete Image
      </Button>
    </StylizerContainer>
  );
};
