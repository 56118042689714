import styled from '@emotion/styled';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import { FC, useRef, useCallback, useEffect } from 'react';
import { useAppSelector, useCanvas, useFabricCore } from 'hooks';
import { DeleteIcon } from 'components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useEvent } from 'react-use';

const StageContainer = styled.div`
  position: relative;
  background-color: #ccc;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 300px;

  & > * {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 980px) {
    margin-bottom: 25px;
    width: 555px;
    height: 555px;
  }
`;

const DeleteButton = styled.button`
  position: absolute;
  background: transparent;
  border: 0;
  top: 10px;
  left: 14px;
  z-index: 1;
  width: 34px;
  height: 39px;
  padding: 0;
  cursor: pointer;

  svg {
    width: 34px;
    height: 39px;
  }
`;

const CANVAS_SIZE = {
  MOBILE: 300,
  DESKTOP: 555
}

export const Stage: FC = () => {
  const { setCanvas, activeSticker, setActiveSticker, canvas, userImage } = useCanvas();
  const { deleteObject } = useFabricCore();
  const { onReady } = useFabricJSEditor();
  const containerRef = useRef<HTMLDivElement>(null);
  const copy = useAppSelector((state) => state.widget.mainWidget.text.stage);

  const isDesktop = useMediaQuery('(min-width:980px)');

  /**
   * Resize canvas on breakpoint change
   */
  useEffect(() => {
    if (!canvas) return;
    
    const { MOBILE, DESKTOP } = CANVAS_SIZE;
    const size = isDesktop? DESKTOP : MOBILE;
    const zoom = isDesktop? 1 : MOBILE/DESKTOP;
    canvas.setDimensions({width: size, height: size});
    canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);

  }, [isDesktop, canvas])

  const addDefaultBackgroundImage = useCallback((canvas: fabric.Canvas) => {
    fabric.Image.fromURL(
      copy.default_background_image,
      (image: fabric.Image) => {
        if (containerRef.current) {
          const zoom = canvas.getZoom();
          canvas.setBackgroundImage(image, () => canvas.renderAll(), {
            scaleX: ((canvas?.width || 1) / zoom) / (image?.width || 1),
            scaleY: ((canvas?.height || 1) / zoom) / (image?.height || 1),
          });
        }
      },
      { crossOrigin: 'anonymous' }
    );
  }, [copy.default_background_image])

  /**
   * Listens for a canvas cleared event
   * When canvas is cleared, we need to redraw the default background image 
   */
  useEvent('canvas:cleared', addDefaultBackgroundImage.bind(this, canvas!), canvas)

  /**
   * Remove the default background image when a user image is added and replace
   * with a background color
   */
  useEffect(() => {
    if (canvas && canvas.getContext() && userImage) {
      canvas.backgroundImage = undefined;
      canvas.setBackgroundColor(copy.canvas_background_color, () => canvas.renderAll());
    }
  }, [canvas, userImage, copy.canvas_background_color]);
  /**
   * Initializes canvas
   */
  const onFabricJSReady = useCallback(
    async (canvas: fabric.Canvas) => {
      canvas.preserveObjectStacking = true;

      onReady(canvas);
      setCanvas(canvas);
      
      canvas.setDimensions({width: CANVAS_SIZE.DESKTOP, height: CANVAS_SIZE.DESKTOP});

      addDefaultBackgroundImage(canvas);

      /**
       * Needed so that the camera preview will update in realtime
       */
      fabric.util.requestAnimFrame(function render() {
        if( canvas.getContext() ) {
          canvas.renderAll();
        }
        fabric.util.requestAnimFrame(render);
      });
    },
    [onReady, containerRef, setCanvas, copy.default_background_image]
  );

  const onDeleteSticker = () => {
    activeSticker && deleteObject(activeSticker);
    setActiveSticker(null);
  };

  return (
    <>
      <StageContainer ref={containerRef}>
        {activeSticker && (
          <DeleteButton onClick={onDeleteSticker}>
            <DeleteIcon></DeleteIcon>
          </DeleteButton>
        )}
        <FabricJSCanvas onReady={onFabricJSReady} aria-hidden='true'></FabricJSCanvas>
      </StageContainer>
    </>
  );
};
