import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MainWidgetData, StickersWidgetData, StylesWidgetData, FormWidgetData } from 'models';
import { RootState } from 'redux/store';

type WidgetStatus = 'idle' | 'pending' | 'loaded';

interface WidgetState {
  mainWidget: MainWidgetData;
  stickersWidget: StickersWidgetData;
  stylesWidget: StylesWidgetData;
  mainWidgetStatus: WidgetStatus;
  stickersWidgetStatus: WidgetStatus;
  stylesWidgetStatus: WidgetStatus;
  formWidget: FormWidgetData;
  formWidgetStatus: WidgetStatus;
}
const initialBaseWidget = {
  data: [],
  metadata: {},
  settings: {},
  sid: 0,
  social: {
    fb: {},
    twitter: {},
  },
  text: {},
  ts: 0,
  wid: '',
};

const initialState: WidgetState = {
  mainWidgetStatus: 'idle',
  stickersWidgetStatus: 'idle',
  stylesWidgetStatus: 'idle',
  formWidgetStatus: 'idle',
  mainWidget: {
    ...initialBaseWidget,
  },
  stickersWidget: {
    ...initialBaseWidget,
  },
  stylesWidget: {
    ...initialBaseWidget,
  },
  formWidget: {
    ...initialBaseWidget,
  },
};

export const widgetSlice = createSlice({
  name: 'widget',
  initialState: initialState,
  reducers: {
    setMainWidgetData: (state: WidgetState, action: PayloadAction<MainWidgetData>) => {
      const payload: MainWidgetData = action.payload;
      state.mainWidget = {
        ...state.mainWidget,
        ...payload,
      };
      state.mainWidgetStatus = 'loaded';
    },
    setStickerWidgetData: (state: WidgetState, action: PayloadAction<StickersWidgetData>) => {
      const payload: StickersWidgetData = action.payload;
      state.stickersWidget = {
        ...state.stickersWidget,
        ...payload,
      };
      state.stickersWidgetStatus = 'loaded';
    },
    setFormWidgetData: (state: WidgetState, action: PayloadAction<FormWidgetData>) => {
      const payload: FormWidgetData = action.payload;
      state.formWidget = {
        ...state.formWidget,
        ...payload,
      };
      state.formWidgetStatus = 'loaded';
    },
  },
});

export const { setMainWidgetData, setStickerWidgetData } = widgetSlice.actions;

// Selectors
export const selectMainWidget = (state: RootState) => state.widget.mainWidget;
export const selectStickersWidget = (state: RootState) => state.widget.stickersWidget;

export default widgetSlice.reducer;
