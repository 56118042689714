import TailorApiService from '@telescope/tailor-js';
import config from 'config';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectMainWidget } from 'redux/reducers/widget';

/**
 * Custom hook that creates a tailor instance
 */
export const useTailor = () => {
  const {
    settings: { tailor_auth_token },
  } = useSelector(selectMainWidget);

  const tailor = useMemo(() => new TailorApiService(tailor_auth_token as string, config.tailor.env as any), [tailor_auth_token]);

  return tailor;
};
