import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Accordion } from 'components';
import { useAppSelector, useCanvas } from 'hooks';
import { fabric } from 'fabric';
import { useCallback, useEffect, useState } from 'react';
import { Input, Select } from 'components';

const Count = styled.span`
  font-size: 10px;
  color: currentColor;
  text-align: right;
  display: block;
`;

export const TextEditor = ({ onValid }: { onValid: (isValid: boolean) => void }) => {
  const { canvas } = useCanvas();
  const [ writeIn, setWriteIn ] = useState<fabric.Text | null>(null);
  const [ writeInLength, setWriteInLength ] = useState<number>(0);
  const [ dropdown, setDropdown ] = useState<fabric.Text | null>(null);
  const content = useAppSelector((state) => state.widget.mainWidget.text.stylizer.add_copy);
  const { stylizer : { add_copy: theme }} = useTheme();

  // Defaults
  const FONT_SIZE = {
    WRITE_IN: parseInt(theme.write_in.font_size, 10) || 30,
    DROPDOWN: parseInt(theme.dropdown.font_size, 10) || 40,
  }
  const TEXTBOX_WIDTH = {
    WRITE_IN: 250,
    DROPDOWN: 450,
  }

  useEffect(() => {
    if (!canvas) return;

    const writeInTextBox = initializeTextBox({
      top: 355,
      fontSize: FONT_SIZE.WRITE_IN,
      fontFamily: theme.write_in.font_family || 'sans-serif',
      fill: theme.write_in.color || "#fff",
      textAlign: 'center',
      width: TEXTBOX_WIDTH.WRITE_IN,
      fontWeight: 900,
      shadow: '2px 2px 10px rgba(0,0,0,0.5)'
    })
    setWriteIn(writeInTextBox);

    const dropdownTextbox = initializeTextBox({
      top: 415,
      fontSize: FONT_SIZE.DROPDOWN,
      fontFamily: theme.dropdown.font_family || 'cursive',
      fill: theme.dropdown.color || "#fff",
      textAlign: 'left',
      width: TEXTBOX_WIDTH.DROPDOWN,
      fontWeight: 400,
    })
    setDropdown(dropdownTextbox);

  }, [canvas])

  const initializeTextBox = useCallback((options: fabric.TextOptions) => {
    const el = new fabric.Textbox("", {
      height: 46,
      left: 20,
      angle: -8,
      evented: false,
      selectable: false,
      ...options
    });

    canvas?.add(el);
    canvas?.bringToFront(el);

    return el;
  }, [canvas])

  const handleWriteInChange = useCallback((value: string) => {
    if (!writeIn) return;
    const text = value.substring(0,14).toLocaleUpperCase();
    setWriteInLength(text.length);
    
    handleTextToCanvas(writeIn, text, { fixedWidth: TEXTBOX_WIDTH.WRITE_IN, fontSize: FONT_SIZE.WRITE_IN });
    checkIfValid();
  }, [writeIn]);

  const handleDropdownChange = (value: string) => {
    if (!dropdown) return;
    
    handleTextToCanvas(dropdown, value, { fixedWidth: TEXTBOX_WIDTH.DROPDOWN, fontSize: FONT_SIZE.DROPDOWN });
    checkIfValid();
  }

  const handleTextToCanvas = (textObj: fabric.Text, value: string, { fixedWidth, fontSize } : { fixedWidth: number, fontSize: number }) => {
    textObj.set('text', value);
    textObj.set({ fontSize });

    if (textObj.width! > fixedWidth) {
      textObj.set({
        fontSize: textObj.fontSize! * (fixedWidth / (textObj.width! + 1))
      });
      textObj.set({ width: fixedWidth })
    }

    while (textObj.textLines.length > 1) {
      textObj.set({
        fontSize: textObj.fontSize! - 1,
      });
    }

    canvas?.bringToFront(textObj);
    canvas?.renderAll();
  } 

  const checkIfValid = () => {
    onValid(!!(writeIn?.get('text') && dropdown?.get('text')));
  }

  return (
    <Accordion title={content.headline}>
      {/* divs below fix a FabricJS issue where initial text will render with browser's default fonts instead of imported ones */}
      <div style={{fontFamily: theme.write_in.font_family, fontSize: 1}}>&nbsp;</div>
      <div style={{fontFamily: theme.dropdown.font_family, fontSize: 1}}>&nbsp;</div>

      <div>
        <Input id="write-in" maxLength={14} type="text" placeholder={content.write_in.placeholder}
              onChange={(e) => handleWriteInChange(e.currentTarget.value)} label={content.write_in.label}
              required style={{marginBottom: 5}}/>
        <Count>{writeInLength} / 14</Count>
      </div>

      <Select id="select-copy" onChange={(e) => {
            handleDropdownChange(e.currentTarget.value); 
            e.currentTarget.classList.remove('unselected');
          }} 
          placeholder={content.dropdown.placeholder} label={content.dropdown.label}
          options={content.dropdown.options.map((o: string) => ({ value: o, copy: o }))} required />
    </Accordion>
  )
}