import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { mq } from 'utils';

const fadeIn = keyframes({
  '0%': {
    opacity: 0,
  },
  '100%': {
    opacity: 1,
  },
});

export const AccordionContainer = styled.div((props) => {
  return {};
});

export const AccordionButton = styled.div<{ expanded: boolean }>(({ theme, expanded }) => {
  const { desktop, disabled: disabledStyles, ...defaultStyles } = theme.stylizer.accordions.accordion_header;

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.global.colors.primary,
    padding: '10px 20px 10px 10px',
    fontFamily: theme.global.fonts.primary,
    fontWeight: 'bold',
    color: '#FFF',
    cursor: 'pointer',
    borderRadius: 5,
    position: 'relative',
    ...defaultStyles,

    '::after': {
      content: '""',
      display: 'inline-block',
      borderRight: `3px solid currentColor`,
      borderBottom: `3px solid currentColor`,
      width: 12,
      height: 12,
      right: 10,
      transform: expanded ? 'rotate(-135deg)' : 'rotate(45deg)',
      transition: 'transform 0.2s linear',
      position: 'absolute'
    },

    '&[aria-disabled="true"]': {
      cursor: 'not-allowed',
      ...disabledStyles,
    },

    [mq.desktop]: {
      textAlign: 'left',
      padding: '15px 35px 15px 15px',
      ...desktop,

      '::after': {
        right: 15,
      }
    },
  };
});

export const AccordionContent = styled.div<{ expanded: boolean }>(({ expanded, theme }) => {
  const { desktop, ...defaultStyles } = theme.stylizer.accordions.accordion_content;

  return {
    '& > *': {
      display: expanded ? 'block' : 'none',
      padding: '5px 8px 15px 8px',
      border: '1px solid #a2a2a2',
      borderTop: 'none',
      animation: `${fadeIn} 0.2s ease-in`,
      ...defaultStyles,
    },

    p: {
      marginBottom: 20,
    },

    [mq.desktop]: {
      '& > *': {
        padding: '10px 15px 20px 15px',
        ...desktop,
      },
    },
  };
});

export const AccordionHeader = styled.div((props) => {
  return {};
});
