import TagManager from 'react-gtm-module';

interface GAEvent {
  type: string;
  payload: {
    event: string;
    payload: {
      content: string;
      category: string;
    }
  };
}

interface GAPageView {
  type: string;
  payload: string;
}

export enum GACategory {
  SITE = 'Site',
  FOOTER = 'Footer',
  SHARE = 'Share',
  AD = 'Ad',
  FRAME = 'Frame',
  STICKER = 'Sticker',
  SELECT_PHOTO = 'Select Photo',
  EDIT = 'Edit',
}

export enum GAAction {
  BUTTON_CLICK = 'Button Click',
  LINK_CLICK = 'Link Click',
}

export enum GAPage {
  LANDING_PAGE = 'Landing Page',
  CLOSED_PAGE = 'Closed Page',
  GEO_PAGE = 'Out of Geo Page',
}

let _isGoogleInitialized = false;
let _googleQueue: Array<GAEvent | GAPageView> = [];

export const initializeGoogleAnalytics = (
    gtmContainerId: string, 
    { gaClientMeasurementId, gaProductMeasurementId }: 
    { gaClientMeasurementId: string, gaProductMeasurementId: string }
  ) => {

  TagManager.initialize({
    gtmId: gtmContainerId as string,
    dataLayer: {
      client_ga_id: gaClientMeasurementId,
      product_ga_id: gaProductMeasurementId
    },
  });

  _isGoogleInitialized = true;

  if (_googleQueue.length > 0) {
    _googleQueue.forEach((call) => {
      switch (call.type) {
        case 'page':
          trackGooglePage(call.payload as GAPageView['payload']);
          break;
        case 'event':
          const { event, payload } = call.payload as GAEvent['payload'];
          trackGoogleEvent(event, payload);
          break;
        default:
        // do nothing
      }
    });

    _googleQueue = [];
  }
}

export const isInitialized = () => {
  return _isGoogleInitialized;
};

export const trackGooglePage = (page: string) => {
  if (!isInitialized()) {
    _googleQueue.push({ type: 'page', payload: page });
    return;
  }

  TagManager.dataLayer({ dataLayer: { event: "pageView", pageTitle: page }});
};

export const trackGoogleEvent = (event: string, payload: GAEvent['payload']['payload']) => {
  if (!isInitialized()) {
    _googleQueue.push({ type: 'event', payload: { event, payload } });
    return;
  }
  TagManager.dataLayer({ dataLayer: { event, ...payload }});
};


export const checkClickEvent = (evt: MouseEvent) => {
  if (!evt) return;

  const element = _getTargetElement(evt.target as HTMLElement) as HTMLElement;
  const elementTag = element.tagName.toLowerCase();
  const customCategory = element.getAttribute('data-ga-category');
  const customAction = element.getAttribute('data-ga-action');
  const customLabel = element.getAttribute('data-ga-label');
  const dontTrack = element.getAttribute('data-ga-no-track');
  if (dontTrack !== null) return;

  if (elementTag === 'a' || elementTag === 'button') {
    const category = customCategory || GACategory.SITE;
    const action = customAction || (elementTag === 'a' ? GAAction.LINK_CLICK : GAAction.BUTTON_CLICK);
    const content = customLabel || element.textContent || '';

    trackGoogleEvent(action, { content, category });
  }
};

export const addClickListener = () => {
  document.addEventListener('click', checkClickEvent, false);
};

export const removeClickListener = () => {
  document.removeEventListener('click', checkClickEvent, false);
};

/**
 * If the target element is an element that is wrapped inside a button or link
 * we want to find the closest ancestor that is a button or anchor and use that as
 * the target element instead.
 */
const _getTargetElement = (el: HTMLElement) => {
  const elementTag = el.tagName.toLowerCase();
  if (elementTag === 'a' || elementTag === 'button') {
    return el;
  }

  const parentTag = el.closest('a, button');
  if (parentTag) {
    return parentTag;
  }
  return el;
};