import { FC, createContext, useState } from 'react';
import { fabric } from 'fabric';
import { useList } from 'react-use';
import { ListActions } from 'react-use/lib/useList';
import { FrameOption } from 'models';

interface CanvasState {
  canvas: fabric.Canvas | null;
  setCanvas: (canvas: fabric.Canvas) => void;
  activeObject: fabric.Object | null;
  setActiveObject: (object: fabric.Object | null) => void;
  userImage: fabric.Image | null;
  setUserImage: (image: fabric.Image | null) => void;
  frame: FrameOption | null;
  setFrame: (frame: FrameOption | null) => void;
  activeSticker: fabric.Image | null;
  setActiveSticker: (sticker: fabric.Image | null) => void;
  stickers: fabric.Image[];
  stickerActions: ListActions<fabric.Image>;
}

export const CanvasContext = createContext<CanvasState>({
  canvas: null,
  setCanvas: () => {},
  activeObject: null,
  setActiveObject: () => {},
  userImage: null,
  setUserImage: () => {},
  frame: null,
  setFrame: () => {},
  activeSticker: null,
  setActiveSticker: () => {},
  stickers: [],
  stickerActions: {} as ListActions<fabric.Image>,
});

interface CanvasProviderProps {
  children: React.ReactNode;
}

export const CanvasProvider: FC<CanvasProviderProps> = ({ children }) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [activeObject, setActiveObject] = useState<fabric.Object | null>(null);
  const [userImage, setUserImage] = useState<fabric.Image | null>(null);
  const [frame, setFrame] = useState<FrameOption | null>(null);
  const [activeSticker, setActiveSticker] = useState<fabric.Image | null>(null);
  const [stickers, stickerActions] = useList<fabric.Image>([]);

  const context = {
    canvas,
    setCanvas,
    activeObject,
    setActiveObject,
    userImage,
    setUserImage,
    frame,
    setFrame,
    activeSticker,
    setActiveSticker,
    stickers,
    stickerActions,
  };

  return <CanvasContext.Provider value={context}>{children}</CanvasContext.Provider>;
};
