import styled from '@emotion/styled';
import { isTrue } from '@telescope/utility-helpers';
import { useAppSelector } from 'hooks';
import React from 'react';
import { GACategory, mq } from 'utils';

const FooterContainer = styled.footer(({ theme }) => {
  const { desktop, ...defaultStyles } = theme.footer.container;

  return {
    background: theme.global.colors.primary,
    display: 'flex',
    flexDirection: 'column',
    padding: 18,
    marginTop: 15,
    ...defaultStyles,

    [mq.desktop]: {
      padding: 28,
      ...desktop,
    },
  };
});

const FooterLink = styled.a(({ theme }) => {
  const { active, hover, desktop, margin, ...defaultStyles } = theme.footer.link;
  const { flexDirection } = theme.footer.inner_container;
  const { flexDirection: desktopFlexDirection } = theme.footer.inner_container.desktop;
  const gap = theme.footer.link_spacing;

  return {
    color: '#FFF',
    textDecoration: 'underline',
    fontSize: 12,
    ...defaultStyles,

    ':active': {
      ...active,
    },

    ':hover': {
      ...hover,
    },

    ':not(:first-child)': {
      margin: flexDirection === 'row' ? `0 0 0 ${gap.mobile}` : `${gap.mobile} 0 0 0`,
    },

    '&#ot-sdk-btn.ot-sdk-show-settings': {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'currentcolor',
      padding: 'inherit',
      ...defaultStyles
    },

    '&#ot-sdk-btn.ot-sdk-show-settings:hover': {
      backgroundColor: 'transparent',
      color: 'currentcolor',
      ...hover,
    },

    [mq.desktop]: {
      fontSize: 16,
      ...desktop,

      ':not(:first-child)': {
        margin: desktopFlexDirection === 'row' ? `0 0 0 ${gap.desktop}` : `${gap.desktop} 0 0 0`,
      },
    },
  };
});

const FooterInnerContainer = styled.div(({ theme }) => {
  const { desktop, ...defaultStyles } = theme.footer.inner_container;

  return {
    display: 'flex',
    maxWidth: 980,
    ...defaultStyles,

    [mq.desktop]: {
      ...desktop,
    },
  };
});
interface Link {
  url: string;
  text: string;
  open_new_window: string;
}

export const Footer = () => {
  const copy = useAppSelector((state) => state.widget.mainWidget.text.footer);
  const onetrustId = useAppSelector((state) => state.widget.mainWidget.settings.onetrust_id);
  const links = copy.links
    .filter((link: Link) => link.url)
    .map((link: Link, index: number) => {
      const target = isTrue(link.open_new_window) ? '_blank' : 'self';
      return (
        <FooterLink
          key={index}
          href={link.url}
          dangerouslySetInnerHTML={{ __html: link.text }}
          target={target}
          data-ga-category={GACategory.FOOTER}
        />
      );
    });

  if (!isTrue(copy.display)) {
    return null;
  }

  return (
    <FooterContainer>
      <FooterInnerContainer>
        {links}
        { !!onetrustId && <FooterLink
          key="onetrust"
          as="button"
          id="ot-sdk-btn"
          className="ot-sdk-show-settings"
          dangerouslySetInnerHTML={{ __html: 'Cookie Settings' }}
          data-ga-category={GACategory.FOOTER}
        /> }
      </FooterInnerContainer>
    </FooterContainer>
  );
};
