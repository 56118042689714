import styled from '@emotion/styled';
import { Flex } from 'components/layout';
import { useAppSelector, useMediaQuery } from 'hooks';
import React from 'react';
import { FC } from 'react';
import { GACategory, mq } from 'utils';

const ImageAd = styled.a((props) => {
  return {
    width: 300,
    height: 50,

    [mq.desktop]: {
      width: 728,
      height: 90,
    },
  };
});

interface AdPlacementProps {
  position: 'top' | 'bottom';
}

export const AdPlacement: FC<AdPlacementProps> = ({ position }) => {
  const breakpoint = useMediaQuery(['(min-width: 980px)'], ['desktop'], 'mobile');
  const copy = useAppSelector((state) => state.widget.mainWidget.text.ads[position]);
  const { image, url, embed, aria_label } = copy[breakpoint];
  return (
    <Flex horizontalAlign='center' padding='10px'>
      {image && (
        <ImageAd
          href={url}
          target='_blank'
          rel='noreferrer'
          aria-label='Advertisement'
          data-ga-category={GACategory.AD}
          data-ga-label={url}>
          <img src={image} alt={aria_label} />
        </ImageAd>
      )}
      {embed && <div dangerouslySetInnerHTML={{ __html: embed }}></div>}
    </Flex>
  );
};
