export class WebRTCUtil {
  /**
   * Gets a list of connected media devices matching a given MediaDeviceKind
   * @param type type of device to check for
   * @returns list of available devices matching the specified type
   */
  public static async getConnectedDevices(type: MediaDeviceKind): Promise<MediaDeviceInfo[]> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter((device) => device.kind === type);
  }

  /**
   * Checks if the browser has access to an available video camera
   * @returns true if a videoinput device is found
   */
  public static async hasVideoCamera(): Promise<boolean> {
    const videoCameras = await WebRTCUtil.getConnectedDevices('videoinput');

    return !!videoCameras.length;
  }

  /**
   * Returns an image from a video element stream
   * @param videoEl Video element to capture screenshot from
   * @returns url of image captured (PNG)
   */
  public static captureScreenshot(videoEl: HTMLVideoElement): string {
    const tempCanvas = document.createElement('canvas');

    tempCanvas.width = videoEl.width;
    tempCanvas.height = videoEl.height;
    const context = tempCanvas.getContext('2d');

    if (!context) {
      throw Error('captureScreenshot: Unable to create canvas context');
    }

    context.drawImage(videoEl, 0, 0, tempCanvas.width, tempCanvas.height);
    return tempCanvas.toDataURL();
  }
}
