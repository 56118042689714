import styled from '@emotion/styled';
import React from 'react';
import { ErrorLabel, HelpTextLabel } from './Form.styles';

const SelectContainer = styled.div<{ isRequired?: boolean }>(({ isRequired, theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',

    select: {
      padding: 6,
      width: '100%',
      borderRadius: 0,
      color: 'currentColor',
      background: '#fff',
      border: '1px solid #666',
      height: 35,

      '&.unselected': {
        ...theme.form.placeholders,
      }
    },
    
    '.wrapper': {
      position: 'relative',
    },

    '.wrapper:after': {
      content: '""',
      display: 'inline-block',
      borderRight: `3px solid #fff`,
      borderBottom: `3px solid #fff`,
      width: 12,
      height: 12,
      transform: 'rotate(45deg)',
      transition: 'transform 0.2s linear',
      position: 'absolute',
      right: 12,
      top: 9,
      pointerEvents: 'none'
    },

    '.wrapper:before': {
      content: "''",
      height: 'calc(100% - 2px)',
      width: 36,
      backgroundColor: theme.form.links.color,
      position: 'absolute',
      right: 1,
      top: 1,
      pointerEvents: 'none'
    },

    label: {
      padding: '6px 0',
      display: 'block',
      ...theme.form.labels,

      ...(isRequired && {
        ':after': {
          content: '" *"',
          color: theme.form.error.color,
        },
      }),
    },

    span: {
      display: 'block',
    }
  };
});

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
  id: string;
  label?: string;
  error?: string;
  helpText?: string;
  options: { value: string, copy: string }[]
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { options, label, id, error, helpText, required, placeholder, className, ...nativeProps } = props;
  return (
    <SelectContainer isRequired={required}>
      {label && (
        <label id={`input__label-${id}`} htmlFor={id}>
          {label}
        </label>
      )}

      <div className="wrapper">
        <select
          ref={ref}
          id={id}
          aria-labelledby={`input__label-${id}`}
          aria-invalid={error ? 'true' : 'false'}
          required={required}
          className={`${className} ${placeholder? 'unselected' : ''}`}
          {...nativeProps}>
            {placeholder && <option value="" selected disabled>{placeholder}</option>}
            {options.map(option => <option value={option.value}>{option.copy}</option>)}
        </select>
      </div>

      {helpText && <HelpTextLabel>{helpText}</HelpTextLabel>}
      {error && <ErrorLabel role='alert'>{error}</ErrorLabel>}
    </SelectContainer>
  );
});
