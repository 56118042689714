import React, { useState } from 'react';
import { FC } from 'react';
import { KeyboardUtil, KeyCode } from 'utils';

import { AccordionContainer, AccordionHeader, AccordionContent, AccordionButton } from './Accordion.styles';

const ACCORDION_HEADER_ID_PREFIX = 'accordion__heading-';
const ACCORDION_CONTENT_ID_PREFIX = 'accordion__content-';
interface AccordionProps {
  openInitially?: boolean;
  title: string;
  disabled?: boolean;
  children: React.ReactNode;
}

export const Accordion: FC<AccordionProps> = ({ title, openInitially = false, disabled = false, children }) => {
  const [open, setIsOpen] = useState(openInitially);
  const accordionId = title.toLowerCase().split(' ').join('-');
  const toggleExpanded = () => {
    if (disabled) {
      return;
    }

    setIsOpen(!open);
  };

  const handleKeyPress = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    const keyCode = evt.key as KeyCode;

    if (disabled) {
      return;
    }

    if (KeyboardUtil.isSpace(keyCode) || KeyboardUtil.isEnter(keyCode)) {
      evt.preventDefault();
      setIsOpen(!open);
    }
  };

  return (
    <AccordionContainer>
      <AccordionHeader role='heading'>
        <AccordionButton
          id={`${ACCORDION_HEADER_ID_PREFIX}${accordionId}`}
          expanded={open}
          role='button'
          aria-disabled={disabled}
          aria-expanded={open}
          aria-controls={`${ACCORDION_CONTENT_ID_PREFIX}${accordionId}`}
          tabIndex={0}
          onClick={toggleExpanded}
          onKeyDown={handleKeyPress}>
          {title}
        </AccordionButton>
      </AccordionHeader>
      <AccordionContent
        id={`accordion__content-${accordionId}`}
        expanded={open}
        role='region'
        aria-labelledby={`${ACCORDION_HEADER_ID_PREFIX}${accordionId}`}>
        {<div>{children}</div>}
      </AccordionContent>
    </AccordionContainer>
  );
};
