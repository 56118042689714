import styled from '@emotion/styled';
import { isTrue } from '@telescope/utility-helpers';
import { Flex } from 'components/layout';
import { useMediaQuery } from 'hooks';
import { FormOption } from 'models';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Checkbox } from './Checkbox';
import { parseValidations } from './Form.utils';
import { Input } from './Input';
import { mq } from 'utils';

const FormContainer = styled.form((props) => {
  return {
    '& .form__text-fields > *': {
      flex: '1',
    },

    [mq.desktop]: {
      '& .form__text-fields > div > *': {
        width: 'calc(50% - 20px)'
      },
    }
  };
});

type FieldType = 'text' | 'checkbox' | 'tel'; // Support more types in future

interface FormProps {
  formFields: FormOption[];
  formData?: Record<string, unknown>;
  onChange?: (data: Record<string, unknown>) => void;
  onValidChange?: (isValid: boolean) => void;
}

interface CustomFormFields {
  [name:string]: string;
}

export const Form: FC<FormProps> = ({ formFields, onChange, onValidChange, formData }) => {
  const { register, watch, formState, setValue } = useForm<CustomFormFields>({
    mode: 'onTouched',
    defaultValues: formData as {},
    reValidateMode: 'onChange',
  });
  const { errors, isValid, isDirty } = formState;
  const allValues = watch();

  useEffect(() => {
    if (isDirty) {
      onChange && onChange(allValues);
    }
  }, [allValues, onChange, isDirty]);

  useEffect(() => {
    onValidChange && onValidChange(isValid);
  }, [isValid, onValidChange]);

  const isMobile = useMediaQuery(['(min-width: 980px)'], [false], true);
  const renderFormFields = (type: FieldType) => {
    const enabledFields = formFields
      .filter((field) => isTrue(field.display))
      .filter((field) => field.type === type)
      .map((field) => {
        const type = field.type as FieldType;
        const validations = parseValidations(field.validations);

        if (type === 'checkbox') {
          return (
            <Checkbox
              id={field.name}
              key={field.name}
              required={validations.required?.value ?? false}
              error={errors[field.name]?.message}
              label={field.label}
              {...register(field.name, validations)}
            />
          );
        }

        return (
          <Input
            id={field.name}
            key={field.name}
            type={type}
            label={field.label}
            error={errors[field.name]?.message}
            placeholder={field.placeholder}
            helpText={field.help_text}
            maxLength={validations.maxLength.value}
            required={validations.required?.value ?? false}
            {...register(field.name, {
              onChange: e => setValue(field.name, e.target.value),
              ...validations
            })}
          />
        );
      });

    return enabledFields;
  };

  return (
    <FormContainer>
      <Flex gap={15}>
        <Flex className='form__text-fields' horizontal={!isMobile} gap={!isMobile ? 20 : 10} wrap={true}>
          {renderFormFields('text')}
          {renderFormFields('tel')}
        </Flex>
        {renderFormFields('checkbox')}
      </Flex>
    </FormContainer>
  );
};
