import { useCallback, useEffect, useState } from 'react';

export const useMediaQuery = <T>(queries: string[], values: T[], defaultValue: T) => {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));
  // Function that gets value based on matching media query
  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    if (index === -1) {
      return defaultValue;
    }

    return values[index];
  }, [defaultValue, mediaQueryLists, values]);

  // State and setter for matched value
  const [value, setValue] = useState<T>(getValue);
  useEffect(() => {
    const handler = () => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    // Remove listeners on cleanup
    return () => mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [getValue, mediaQueryLists]);
  return value;
};
