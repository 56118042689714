import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { DOMUtil } from 'utils';

export const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${DOMUtil.isIframed() ? 'auto' : '100vh'};
  position: relative;
`;

// @TODO - Flesh out theme variables
export const defaultTheme: Theme = {
  colors: {
    primary: '#153F4C',
    primaryLight: '',
    primaryDark: '',
    secondary: '#0DAA9E',
    secondaryLight: '#91DDCA',
    secondaryDark: '',
  },
};
