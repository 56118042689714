// Helper function that takes a gap as a string and converts it into a { value, unit } representation.
const _getValueUnitGap = (gap: string): { value: number; unit: string } => {
  const numericalPart = parseFloat(gap);
  const numericalValue = isNaN(numericalPart) ? 0 : numericalPart;
  const numericalString = isNaN(numericalPart) ? '' : numericalPart.toString();

  const unitPart = gap.substring(numericalString.toString().length);

  return {
    value: numericalValue,
    unit: unitPart || 'px',
  };
};

/**
 * Takes in a gap size in either a CSS-style format (e.g. 10 or "10px")
 *  or a key of a themed spacing value (e.g. "s1").
 * Returns the separate numerical value of the padding (e.g. 10)
 *  and the CSS unit (e.g. "px").
 */
export const parseGap = (
  gap: number | string
): { rowGap: { value: number; unit: string }; columnGap: { value: number; unit: string } } => {
  if (gap === undefined || gap === '') {
    return {
      rowGap: {
        value: 0,
        unit: 'px',
      },
      columnGap: {
        value: 0,
        unit: 'px',
      },
    };
  }

  if (typeof gap === 'number') {
    return {
      rowGap: {
        value: gap,
        unit: 'px',
      },
      columnGap: {
        value: gap,
        unit: 'px',
      },
    };
  }

  const splitGap = gap.split(' ');

  // If the array has more than two values, then return 0px.
  if (splitGap.length > 2) {
    return {
      rowGap: {
        value: 0,
        unit: 'px',
      },
      columnGap: {
        value: 0,
        unit: 'px',
      },
    };
  }

  // If the array has two values, then parse each one.
  if (splitGap.length === 2) {
    return {
      rowGap: _getValueUnitGap(splitGap[0]),
      columnGap: _getValueUnitGap(splitGap[1]),
    };
  }

  // Else, parse the numerical value and pass it as both the vertical and horizontal gap.
  const calculatedGap = _getValueUnitGap(gap);

  return {
    rowGap: calculatedGap,
    columnGap: calculatedGap,
  };
};
