import { useCanvas } from 'hooks';
import { useCallback } from 'react';
import { FabricUtil, FilterType } from 'utils/fabric';
import { IBaseFilter, IDataURLOptions, IImageOptions } from 'fabric/fabric-impl';

const MAX_IMAGE_SIZE = 2048;

const findFilterIndex = (filters: IBaseFilter[], filterKey: keyof IBaseFilter) => {
  var index = -1;
  filters.forEach((filter: IBaseFilter, i) => {
    const exists = filter[filterKey];

    if (typeof exists === 'number') index = i;
  });
  return index;
};

export function useFabricCore() {
  const { canvas } = useCanvas();

  // Add Image to canvas
  const addImage = useCallback(
    async (url: string, options?: IImageOptions) => {
      if (canvas) {
        let image = await FabricUtil.createImageFromURL(url, options);

        // Images larger than the fabric.maxTextureSize must be resized to support filters, due to webGL constraints.
        // MAX_IMAGE_SIZE is a reasonable compromise
        if (Math.max(image.width ?? 0, image.height ?? 0) > MAX_IMAGE_SIZE) {
          image = await FabricUtil.resizeImage(image, MAX_IMAGE_SIZE);
        }

        canvas.add(image);
        canvas.requestRenderAll();

        return image;
      }

      return Promise.reject('Canvas element not initialized');
    },
    [canvas]
  );

  // Add Image to canvas
  const addVideo = useCallback(
    (videoEl: HTMLVideoElement, options?: IImageOptions) => {
      if (canvas) {
        const video = FabricUtil.createImage(videoEl, options);

        canvas.add(video);
        canvas.requestRenderAll();

        return video;
      }
    },
    [canvas]
  );

  //Apply Filter to Image
  const applyFilter = useCallback(
    (filterType: FilterType, image: fabric.Image, value: any) => {
      if (canvas) {
        const filter = FabricUtil.createFilter(filterType, value);
        const filterIndex = findFilterIndex(image.filters || [], filterType as keyof IBaseFilter);

        // If the same filter has already been applied, replace it
        if (filterIndex < 0) {
          image.filters?.push(filter);
        } else {
          image.filters ? (image.filters[filterIndex] = filter) : (image.filters = []);
        }

        image.applyFilters();
        canvas.requestRenderAll();
      }
    },
    [canvas]
  );

  const deleteObject = useCallback(
    (...objects: fabric.Object[]) => {
      if (canvas) {
        canvas.remove(...objects);
      }
    },
    [canvas]
  );

  const exportCanvasAsImage = useCallback(
    (options?: IDataURLOptions) => {
      if (canvas) {
        const dataURL = canvas.toDataURL(options);
        return dataURL;
      }
    },
    [canvas]
  );

  const lockCanvas = useCallback(() => {
    if (canvas) {
      canvas.getObjects().forEach((obj) => {
        obj.selectable = false;
      });
      canvas.discardActiveObject();
      canvas.requestRenderAll();
    }
  }, [canvas]);

  const unlockCanvas = useCallback(() => {
    if (canvas) {
      canvas.getObjects().forEach((obj) => {
        obj.selectable = true;
      });
      canvas.requestRenderAll();
    }
  }, [canvas]);

  return {
    addImage,
    addVideo,
    deleteObject,
    applyFilter,
    exportCanvasAsImage,
    lockCanvas,
    unlockCanvas,
  };
}

export default useFabricCore;
