import { useContext } from 'react';
import { CanvasContext } from 'contexts';

export function useCanvas() {
  const {
    setCanvas,
    canvas,
    activeObject,
    setActiveObject,
    userImage,
    setUserImage,
    frame,
    setFrame,
    activeSticker,
    setActiveSticker,
    stickers,
    stickerActions,
  } = useContext(CanvasContext);

  return {
    setCanvas,
    canvas,
    activeObject,
    setActiveObject,
    userImage,
    setUserImage,
    frame,
    setFrame,
    activeSticker,
    setActiveSticker,
    stickers,
    stickerActions,
  };
}

export default useCanvas;
