import { fabric } from 'fabric';

export type FilterType = 'saturation' | 'brightness' | 'contrast';

export class FabricUtil {
  public static createImageFromURL(url: string, options?: fabric.IImageOptions): Promise<fabric.Image> {
    return new Promise<fabric.Image>((resolve) => {
      fabric.Image.fromURL(
        url,
        (image) => {
          resolve(image);
        },
        { crossOrigin: 'anonymous', ...options }
      );
    });
  }

  public static createImage(
    element: HTMLImageElement | HTMLVideoElement,
    options?: fabric.IImageOptions
  ): fabric.Image {
    return new fabric.Image(element, { crossOrigin: 'anonymous', ...options });
  }

  public static createFilter(filterType: FilterType, value: number) {
    switch (filterType) {
      case 'saturation':
        return new fabric.Image.filters.Saturation({ saturation: parseFloat((value / 100).toFixed(2)) });
      case 'brightness':
        return new fabric.Image.filters.Brightness({ brightness: parseFloat((value / 255).toFixed(2)) });
      case 'contrast':
        return new fabric.Image.filters.Contrast({ contrast: parseFloat((value / 255).toFixed(2)) });
    }
  }

  public static resizeImage(img: fabric.Image, maxSize: number): Promise<fabric.Image> {
    const imageWidth = img.width ?? 0;
    const imageHeight = img.height ?? 0;

    //create temp canvas
    const canvas = document.createElement('canvas');
    //scale image
    if (imageHeight >= imageWidth) {
      canvas.height = maxSize;
      canvas.width = (maxSize / imageHeight) * imageWidth;
    } else {
      canvas.width = maxSize;
      canvas.height = (maxSize / imageWidth) * imageHeight;
    }
    //draw to canvas
    const context = canvas.getContext('2d');

    if (!context) {
      throw Error('resizeImage: Unable to create canvas context');
    }

    context.drawImage(img.getElement(), 0, 0, canvas.width, canvas.height);

    //Create new fabric image
    return FabricUtil.createImageFromURL(context.canvas.toDataURL());
  }
}
