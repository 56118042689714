import { ThemeProvider } from '@emotion/react';
import { LoadingSpinner } from '@telescope/react-components';
import useCMS from '@telescope/react-hooks/useCMS';
import useGeo from '@telescope/react-hooks/useGeo';
import { getQSPs, isTrue } from '@telescope/utility-helpers';
import { Footer, Header, Main, Rules } from 'components';
import { AdPlacement } from 'components/AdPlacement';
import { Error } from 'components/Error/Error';
import config from 'config';
import { CanvasProvider, PymProvider } from 'contexts';
import 'focus-visible';
import { useAppSelector } from 'hooks/useAppSelector';
import { isEmpty } from 'lodash-es';
import { MainWidgetData, StickersWidgetData } from 'models';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMainWidgetData, setStickerWidgetData } from 'redux/reducers/widget';
import { loadFonts } from 'utils';
import * as googleHelpers from 'utils/google-helpers';
import { AppContainer } from './App.styles';
import { Global } from './Global';

export function App() {
  const dispatch = useDispatch();
  const [stylesWid, setStylesWid] = useState<string | null>(null);
  const [stickersWid, setStickersWid] = useState<string | null>(null);
  const mainData = useAppSelector((state) => state.widget.mainWidget);
  const { wid = config.main_wid, sid } = getQSPs();
  const [cms] = useCMS<MainWidgetData>(wid, { sid, pollingFrequency: 5000 });
  const [stylesWidget] = useCMS<StickersWidgetData>(stylesWid, { pollingFrequency: 30000 });
  const [stickersWidget] = useCMS<StickersWidgetData>(stickersWid, { pollingFrequency: 30000 });
  const { inRegion } = useGeo(cms?.settings.countries);

  useEffect(() => {
    if (!cms) return;

    googleHelpers.initializeGoogleAnalytics(cms.settings.gtm_container_id, {
      gaClientMeasurementId: cms.settings.google_analytics_client,
      gaProductMeasurementId: cms.settings.google_analytics_product
    });
    googleHelpers.addClickListener();

    return () => googleHelpers.removeClickListener();
  }, [cms]);

  useEffect(() => {
    if (cms) {
      dispatch(setMainWidgetData(cms));
      setStylesWid(cms.text.app_settings.styles_wid);
      setStickersWid(cms.text.app_settings.stickers_wid);
    }
  }, [cms, dispatch]);

  useEffect(() => {
    if (stickersWidget) {
      dispatch(setStickerWidgetData(stickersWidget));
    }
  }, [stickersWidget, dispatch]);

  if (isEmpty(mainData.text) || isEmpty(stylesWidget) || inRegion === null) {
    return <LoadingSpinner size={40} />;
  }

  loadFonts(
    stylesWidget.text.global.font_imports.map((font: { font_family: string; font_import: string }) => font.font_import)
  );

  return (
    <PymProvider>
      <ThemeProvider theme={stylesWidget.text}>
        <Global></Global>
          <AppContainer>
            {isTrue(cms.text.ads.top.display) && <AdPlacement position='top' />}
            <Header></Header>
            {inRegion && isTrue(cms.settings.window_status) && (
              <CanvasProvider>
                <Main />
              </CanvasProvider>
            )}
            {inRegion && !isTrue(cms.settings.window_status) && <Error errorType='closed' />}
            {inRegion && <Rules />}
            {!inRegion && <Error errorType='out_of_geo' />}
            {isTrue(cms.text.ads.bottom.display) && <AdPlacement position='bottom' />}
            <Footer></Footer>
          </AppContainer>
      </ThemeProvider>
    </PymProvider>
  );
}
