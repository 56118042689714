import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

interface ActiveStickerProperties {
  moveX: number;
  moveY: number;
  scale: number;
  rotate: number;
}
interface ActiveStickerState {
  status: string;
  properties: ActiveStickerProperties;
}

const initialState: ActiveStickerState = {
  status: '',
  properties: {
    moveX: 0,
    moveY: 0,
    scale: 1,
    rotate: 0,
  },
};

export const activeSticker = createSlice({
  name: 'activeSticker',
  initialState: initialState,
  reducers: {
    update: (state: ActiveStickerState, action: PayloadAction<Partial<ActiveStickerProperties>>) => {
      const payload: Partial<ActiveStickerProperties> = action.payload;
      state.properties = { ...state.properties, ...payload };
    },
    reset: (state: ActiveStickerState) => {
      state.properties = { ...initialState.properties };
    },
  },
});

export const { update, reset } = activeSticker.actions;

// Selectors
export const selectActiveStickerTransformValues = (state: RootState) => state.activeStickerTransformValues.properties;

export default activeSticker.reducer;
