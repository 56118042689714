import { merge } from 'lodash-es';

export const generateTailorConfig = (opts = {}) => {
  const defaultOptions = {
    actions: [
      {
        type: 'transform_image',
        inputs: [{ type: 'upload', reference: '1' }],
        output_details: [
          { identifier: 'processed', format: 'JPEG', size: { type: 'MAX_AREA', width: 1920, height: 1080 } },
        ],
      },
      {
        type: 'flex',
        overrides: [
          {
            name: 'image',
            inputs: [{ type: 'action_result', reference: 'processed' }],
          },
        ],
      },
      {
        type: 'save_to_s3',
        inputs: [{ type: 'action_result', reference: 'processed', destination_folder: 'share' }],
      },
    ],
    flex: {
      id: '',
      topics: '',
      ruleset_collection_id: '0',
      user_name: '-',
      screen_name: '',
      message: '-',
      timestamp: '',
      image: '',
      video: '',
      avatar: '',
      user_score: '',
      country: '',
      state: '',
      account_age: '',
      user_lang: '',
      status_lang: '',
      profile_description: '',
      user_timezone: '',
      custom_parameters: {},
    },
  };

  return merge(defaultOptions, opts);
};

export enum TailorJobStatus {
  SETTING_UP = 'Setting Up',
  READY_FOR_PROCESSING = 'Ready for Processing',
  PROCESSING = 'Processing',
  ERROR = 'Error',
  FINISHED = 'Finished',
}
