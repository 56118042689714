import styled from '@emotion/styled';
import { useAppSelector } from 'hooks';
import { FC, useEffect } from 'react';
import { GAPage, mq, trackGooglePage } from 'utils';

const ErrorContainer = styled.main<ErrorProps>(({ theme, errorType }) => {
  const { container, headline, description } = theme[errorType];
  return {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    padding: '50px 10px',
    alignSelf: 'center',
    width: '100%',
    maxWidth: 980,
    ...container,

    h2: {
      marginBottom: 35,
      ...headline,
    },

    p: {
      marginBottom: 20,
      ...description,
    },

    [mq.desktop]: {
      padding: '50px 20px',
      ...container.desktop,

      h2: {
        marginBottom: 35,
        ...headline.desktop,
      },

      p: {
        marginBottom: 20,
        ...description.desktop,
      },
    },
  };
});

const ImageContainer = styled.div<ErrorProps>(({ errorType, theme }) => {
  const { image } = theme[errorType];

  return {
    width: '100%',
    ...image,

    [mq.desktop]: {
      ...image.desktop,
    },
  };
});

const VideoEmbedContainer = styled.div<ErrorProps>(({ errorType, theme }) => {
  const { video } = theme[errorType];
  return {
    paddingTop: '56.25%',
    position: 'relative',
    width: '100%',
    ...video,

    iframe: {
      position: 'absolute',
      top: '0',
      left: '0',
      border: '0',
      width: '100%',
      height: '100%',

      [mq.desktop]: {
        ...video.desktop,
      },
    },
  };
});

type ErrorType = 'closed' | 'out_of_geo';

export interface ErrorProps {
  errorType: ErrorType;
}
export const Error: FC<ErrorProps> = ({ errorType }) => {
  const widgetData = useAppSelector((state) => state.widget.mainWidget.text);
  const copy = widgetData[errorType];

  useEffect(() => {
    const page = errorType === 'closed' ? GAPage.CLOSED_PAGE : GAPage.GEO_PAGE;
    trackGooglePage(page);
  }, [errorType]);

  return (
    <ErrorContainer errorType={errorType}>
      <h2 dangerouslySetInnerHTML={{ __html: copy.headline }} />
      {copy.description && <p dangerouslySetInnerHTML={{ __html: copy.description }} />}
      {copy.image && (
        <ImageContainer errorType={errorType}>
          <img src={copy.image} alt={copy.image_aria_label} />
        </ImageContainer>
      )}
      {copy.video_embed && (
        <VideoEmbedContainer errorType={errorType} dangerouslySetInnerHTML={{ __html: copy.video_embed }} />
      )}
    </ErrorContainer>
  );
};
