import styled from '@emotion/styled';
import { useAppSelector } from 'hooks/useAppSelector';
import { breakpoints, mq } from 'utils';

const StyledHeader = styled.header(({ theme }) => {
  return {
    textAlign: 'center',
    marginBottom: 20,
    background: theme.header.background
  };
});

const ImageContainer = styled.div(({ theme }) => {
  return {
    width: '100%',
    maxWidth: breakpoints.desktop,
    margin: 'auto'
  }
})

const HeadlineContainer = styled.div(({ theme }) => {
  const { desktop, ...defaultStyles } = theme.sub_header;

  return {
    padding: 10,
    display: 'flex',
    h1: {
      maxWidth: breakpoints.desktop,
      margin: 'auto',
      fontSize: 'inherit',
    },
    ...defaultStyles,

    [mq.desktop]: {
      ...desktop,
    },
  };
});

export const Header = () => {
  const copy = useAppSelector((state) => state.widget.mainWidget.text.header);
  const mobileImg = copy.header_image_mobile;
  const desktopImg = copy.header_image_desktop;

  return (
    <StyledHeader>
      { (mobileImg || desktopImg) && 
        <ImageContainer>
          <picture>
            <source srcSet={desktopImg} media={`(min-width: ${breakpoints.desktop}px)`} />
            <img src={mobileImg} alt={copy.header_image_alt} />
          </picture>
        </ImageContainer> }

      {copy.headline && (
        <HeadlineContainer>
          <h1 dangerouslySetInnerHTML={{ __html: copy.headline }}></h1>
        </HeadlineContainer>
      )}
    </StyledHeader>
  );
};
